import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { ColorField } from 'react-admin-color-input';
import { useDispatch } from 'react-redux';
import { consumerHelp } from '../../help';

export const ConsumerLabelList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: consumerHelp } });
  });
  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />} perPage={50}>
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='order' />
        <TextField source='name' />
        <ColorField source='color' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};
