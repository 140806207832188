import React, { Fragment, useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { productHelp } from '../../help';

export const ProductList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: productHelp } });
  });

  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />} perPage={50}>
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='description_1' />
        <TextField source='art_nr_new' label='Art. nr.' />
        <BooleanField source='bobbler_supplier' label='Locked Supplier' emptyText='✘' />
        <BooleanField source='show_in_foodservice' />
        <FunctionField
          label='Images'
          sortBy='pictures'
          render={(record) => {
            if (record.pictures && record.pictures.length) {
              return (
                <Fragment>
                  <CheckIcon title='Yes' />
                  {` ${record.pictures.length}`}
                </Fragment>
              ); // '✔';
            } else {
              return <CloseIcon />; //'✖';
            }
          }}
        />
        <FunctionField
          label='Foodservice images'
          sortBy='fs_pictures'
          render={(record) => {
            if (record.fs_pictures && record.fs_pictures.length) {
              return (
                <Fragment>
                  <CheckIcon title='Yes' />
                  {` ${record.fs_pictures.length}`}
                </Fragment>
              ); // '✔';
            }
            return <CloseIcon />; //'✖';
          }}
        />
        <ChipField label='Range' sortBy='range.name' source='range.name' sortable />
        <ChipField
          label='Range RC'
          sortBy='range.region_code'
          source='range.region_code'
          sortable
        />
        <ArrayField
          label='Categories'
          reference='categories'
          source='categories'
          filter={{ fetchMode: 'reflist' }}
        >
          <SingleFieldList linkType={false}>
            <ChipField source='sys_name' />
          </SingleFieldList>
        </ArrayField>
        <ArrayField
          label='Categories RC'
          reference='categories'
          source='categories'
          filter={{ fetchMode: 'reflist' }}
        >
          <SingleFieldList linkType={false}>
            <ChipField source='region_code' />
          </SingleFieldList>
        </ArrayField>
        <ChipField
          label='Series'
          source='series_item.header'
          sortBy='series_item.header'
          sortable
        />
        <ChipField
          label='Series RC'
          source='series_item.region_code'
          sortBy='series_item.region_code'
          sortable
        />
        <TextField source='ean_number' />
        <TextField source='supplier_nav_id' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};
