import React from 'react'
import { makeRegionChoices } from '../../../utils/regionSelector';
import {Create, SimpleForm, TextInput, required, SelectInput} from 'react-admin'

export const LanguageCreate = props => (
  <Create {...props}>
    <SimpleForm>
          <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth />
          <TextInput source="name" fullWidth={true} />
      </SimpleForm>
  </Create>
);