import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  SelectInput,
  required,
} from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { getProductOptionText } from '../../../utils/functions';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'category';

export const CategoryEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label='Base Category'>
        {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
          <SelectInput
            source='region_code'
            choices={makeRegionChoices()}
            validate={[required()]}
            fullWidth
          />
        ) : null}
        {isFieldAvailable('background', props, RESOURCE_NAME) && (
          <MediaUploader attribute='background' label='Background' accept='images/*' />
        )}
        {isFieldAvailable('name', props, RESOURCE_NAME) && (
          <TextInput source='name' fullWidth={true} />
        )}
        {isFieldAvailable('sys_name', props, RESOURCE_NAME) && (
          <TextInput source='sys_name' fullWidth={true} />
        )}
        {isFieldAvailable('description', props, RESOURCE_NAME) && (
          <TextInput source='description' fullWidth={true} />
        )}
        {isFieldAvailable('title', props, RESOURCE_NAME) && (
          <TextInput source='title' fullWidth={true} />
        )}
        {isFieldAvailable('order', props, RESOURCE_NAME) && (
          <NumberInput min='1' source='order' fullWidth={true} />
        )}
        {isFieldAvailable('products', props, RESOURCE_NAME) && (
          <ReferenceArrayInput
            label='Products'
            reference='products'
            source='products'
            filter={{ fetchMode: 'reflist' }}
            fullWidth={true}
          >
            <AutocompleteArrayInput optionText={getProductOptionText} />
          </ReferenceArrayInput>
        )}
        {isFieldAvailable('ranges', props, RESOURCE_NAME) && (
          <ReferenceArrayInput
            label='Ranges'
            reference='ranges'
            source='ranges'
            filter={{ fetchMode: 'reflist' }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              addLabel
              optionText={(range = {}) => {
                if (range == null) {
                  return '';
                }
                const { region_code = '', name = '' } = range;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceArrayInput>
        )}
      </FormTab>

      <FormTab label='FoodService'>
        {isFieldAvailable('fs_background', props, RESOURCE_NAME) && (
          <MediaUploader attribute='fs_background' label='FS Background' accept='images/*' />
        )}
        {isFieldAvailable('fs_description', props, RESOURCE_NAME) && (
          <TextInput source='fs_description' fullWidth={true} />
        )}
        {isFieldAvailable('fs_title', props, RESOURCE_NAME) && (
          <TextInput source='fs_title' fullWidth={true} />
        )}
        {isFieldAvailable('fs_order', props, RESOURCE_NAME) && (
          <NumberInput min='1' source='fs_order' fullWidth={true} />
        )}
      </FormTab>
    </TabbedForm>
  </Edit>
);
