// TODO this is hiding 'Warning: findDOMNode is deprecated in StrictMode' message
const hideStrictWarning = () => {
  const oldLogError = console.error;
  console.error = (...args) => {
    if (args[0] && args[0].indexOf) {
      if (
        args[0].indexOf("is deprecated in StrictMode") > -1 &&
        args[1] === "findDOMNode"
      ) {
        return null;
      }
    }
    return oldLogError.apply(console, args);
  };
};
export default hideStrictWarning;
