import React from 'react';
import MediaUploader from '../../components/MediaUploader';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { Edit, SimpleForm, NumberInput, TextInput, SelectInput, required } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import { makeRegionChoices } from '../../../utils/regionSelector';

const RESOURCE_NAME = 'consumer-label';

export const ConsumerLabelEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('logo', props, RESOURCE_NAME) ? (
        <MediaUploader attribute='logo' label='Logo' accept='images/*' />
      ) : null}
      {isFieldAvailable('name', props, RESOURCE_NAME) ? (
        <TextInput source='name' fullWidth={true} />
      ) : null}
      {isFieldAvailable('color', props, RESOURCE_NAME) ? (
        <ColorInput source='color' fullWidth={true} />
      ) : null}
      {isFieldAvailable('order', props, RESOURCE_NAME) ? (
        <NumberInput min='1' fullWidth={true} source='order' />
      ) : null}
    </SimpleForm>
  </Edit>
);
