import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  SelectInput,
  required,
} from 'react-admin';
import { getProductOptionText } from '../../../utils/functions';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const CustomerCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
        <TextInput source='name' fullWidth={true} />

        <ReferenceArrayInput
          label='Products'
          reference='products'
          source='products'
          filter={{ fetchMode: 'reflist' }}
          fullWidth={true}
        >
          <AutocompleteArrayInput optionText={getProductOptionText} />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          fullWidth={true}
          label='Countries'
          reference='countries'
          source='countries'
          filter={{ fetchMode: 'reflist' }}
        >
          <AutocompleteArrayInput
            optionText={(country = {}) => {
              if (country == null) {
                return '';
              }
              const { region_code = '', name = '' } = country;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          resource='consumer_labels'
          source='consumer_labels'
          reference='consumer-labels'
          filter={{ fetchMode: 'reflist' }}
          perPage={Number.MAX_SAFE_INTEGER}
          allowEmpty
          label='CONSUMER LABELING ON PACKAGING'
          fullWidth={true}
        >
          <CheckboxGroupInput
            optionText={(label = {}) => {
              if (label == null) {
                return '';
              }
              const { region_code = '', name = '' } = label;
              return `${region_code} - ${name}`;
            }}
            optionValue='id'
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
