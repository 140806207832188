import React from 'react';
import { Filter, SearchInput, Pagination } from 'react-admin';

export const PostPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

export const PostFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)