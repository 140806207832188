import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  SelectInput,
  required,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import MediaUploader from '../../components/MediaUploader';
import { getProductOptionText } from '../../../utils/functions';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'range';

export const RangeEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('icon', props, RESOURCE_NAME) ? (
        <MediaUploader attribute='icon' label='Icon' accept='images/*' />
      ) : null}
      {isFieldAvailable('background', props, RESOURCE_NAME) ? (
        <MediaUploader attribute='background' label='Background' accept='images/*' />
      ) : null}
      {isFieldAvailable('categories', props, RESOURCE_NAME) ? (
        <ReferenceArrayInput
          label='Categories'
          reference='categories'
          source='categories'
          filter={{ fetchMode: 'reflist' }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(categories = {}) => {
              if (categories == null) {
                return '';
              }
              const { region_code = '', sys_name = '' } = categories;
              return `${region_code} - ${sys_name}`;
            }}
          />
        </ReferenceArrayInput>
      ) : null}
      {isFieldAvailable('title', props, RESOURCE_NAME) ? (
        <TextInput fullWidth={true} source='title' />
      ) : null}
      {isFieldAvailable('name', props, RESOURCE_NAME) ? (
        <TextInput fullWidth={true} source='name' />
      ) : null}
      {isFieldAvailable('description', props, RESOURCE_NAME) ? (
        <TextInput fullWidth={true} source='description' />
      ) : null}
      {isFieldAvailable('order', props, RESOURCE_NAME) ? (
        <NumberInput fullWidth={true} source='order' />
      ) : null}
      {isFieldAvailable('products', props, RESOURCE_NAME) ? (
        <ReferenceArrayInput
          label='Products'
          reference='products'
          source='products'
          filter={{ fetchMode: 'reflist' }}
          fullWidth={true}
        >
          <AutocompleteArrayInput optionText={getProductOptionText} />
        </ReferenceArrayInput>
      ) : null}
      {isFieldAvailable('color', props, RESOURCE_NAME) ? (
        <ColorInput source='color' fullWidth={true} />
      ) : null}
    </SimpleForm>
  </Edit>
);
