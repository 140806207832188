import React from 'react';
import { Create, SimpleForm, TextInput, required, SelectInput } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const LogisticMediaCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader attribute='media' label='Media' multiple accept='images/*' />
      <TextInput label='Name' source='name' fullWidth={true} />
    </SimpleForm>
  </Create>
);
