import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  maxValue,
  BooleanInput,
  SelectInput,
  required,
} from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import MapExporter from '../../maps/MapExporter';
import { countryList } from '../../maps/countries';
import { getProductOptionText } from '../../../utils/functions';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'supplier';

export const SupplierEdit = (props) => {
  const [pin, setPin] = useState([-1, -1]);

  const FormSetter = () => {
    const form = useForm();
    if (pin[0] !== -1 || pin[1] !== -1) {
      form.change('map_pos_x', pin[0]);
      form.change('map_pos_y', pin[1]);
    }
    return null;
  };
  const handleMapClick = (event = {}, pathId = '') => {
    const pathElement = document.querySelector(`#${pathId}`);
    if (pathElement) {
      const { clientY = 0, clientX = 0 } = event;
      const {
        bottom = 0,
        right = 0,
        width = 0,
        height = 0,
      } = pathElement.getBoundingClientRect() || {};
      const relativeWidthDiff = right - clientX;
      const relativeHeightDiff = bottom - clientY;
      const widthPercentage = 100 - (relativeWidthDiff / width) * 100;
      const heightPercentage = 100 - (relativeHeightDiff / height) * 100;
      setPin([Math.round(widthPercentage), Math.round(heightPercentage)]);
    }
  };

  return (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        {isFieldAvailable('media', props, RESOURCE_NAME) ? (
          <MediaUploader attribute='media' multiple withVisibilityFeature />
        ) : null}
        {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
          <SelectInput
            source='region_code'
            choices={makeRegionChoices()}
            validate={[required()]}
            fullWidth
          />
        ) : null}
        {isFieldAvailable('name', props, RESOURCE_NAME) ? (
          <TextInput source='name' fullWidth={true} />
        ) : null}
        {isFieldAvailable('address_1', props, RESOURCE_NAME) ? (
          <TextInput source='address_1' fullWidth={true} />
        ) : null}
        {isFieldAvailable('address_2', props, RESOURCE_NAME) ? (
          <TextInput source='address_2' fullWidth={true} />
        ) : null}
        <BooleanInput source='bobbler' fullWidth={true} label='Bobbler / Prevent overwrite' />
        {isFieldAvailable('number', props, RESOURCE_NAME) ? (
          <TextInput source='number' fullWidth={true} label='Number (old)' />
        ) : null}
        {isFieldAvailable('number_new', props, RESOURCE_NAME) ? (
          <TextInput source='number_new' fullWidth={true} label='Number (new)' />
        ) : null}
        {isFieldAvailable('zip_code', props, RESOURCE_NAME) ? (
          <TextInput source='zip_code' fullWidth={true} />
        ) : null}
        {isFieldAvailable('city', props, RESOURCE_NAME) ? (
          <TextInput source='city' fullWidth={true} />
        ) : null}
        {isFieldAvailable('att', props, RESOURCE_NAME) ? (
          <TextInput source='att' fullWidth={true} />
        ) : null}
        {isFieldAvailable('phone', props, RESOURCE_NAME) ? (
          <TextInput source='phone' fullWidth={true} />
        ) : null}
        {isFieldAvailable('short_description', props, RESOURCE_NAME) ? (
          <TextInput source='short_description' fullWidth={true} />
        ) : null}
        {isFieldAvailable('email', props, RESOURCE_NAME) ? (
          <TextInput source='email' fullWidth={true} />
        ) : null}
        {isFieldAvailable('description', props, RESOURCE_NAME) ? (
          <TextInput source='description' fullWidth={true} />
        ) : null}
        {isFieldAvailable('categories', props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            label='Categories'
            reference='categories'
            source='categories'
            filter={{ fetchMode: 'reflist' }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(categories = {}) => {
                if (categories == null) {
                  return '';
                }
                const { region_code = '', sys_name = '' } = categories;
                return `${region_code} - ${sys_name}`;
              }}
            />
          </ReferenceArrayInput>
        ) : null}
        {isFieldAvailable('products', props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            label='Products'
            reference='products'
            source='products'
            filter={{ fetchMode: 'reflist' }}
            fullWidth={true}
          >
            <AutocompleteArrayInput optionText={getProductOptionText} />
          </ReferenceArrayInput>
        ) : null}
        {isFieldAvailable('ranges', props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            label='Ranges'
            reference='ranges'
            source='ranges'
            filter={{ fetchMode: 'reflist' }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(ranges = {}) => {
                if (ranges == null) {
                  return '';
                }
                const { region_code = '', name = '' } = ranges;
                return region_code && name ? `${region_code} - ${name}` : '';
              }}
            />
          </ReferenceArrayInput>
        ) : null}
        {isFieldAvailable('country_code', props, RESOURCE_NAME) ? (
          <AutocompleteInput source='country_code' choices={countryList} fullWidth />
        ) : null}
        {isFieldAvailable('map_pos_x', props, RESOURCE_NAME) &&
          isFieldAvailable('map_pos_y', props, RESOURCE_NAME) && (
            <>
              <FormSetter />
              {isFieldAvailable('country_code', props, RESOURCE_NAME) ? (
                <FormDataConsumer>
                  {({ formData }) => {
                    const { country_code = '', map_pos_x = 0, map_pos_y = 0 } = formData || {};
                    return (
                      <MapExporter
                        countryCode={country_code}
                        posX={map_pos_x}
                        posY={map_pos_y}
                        handleMapClick={handleMapClick}
                      />
                    );
                  }}
                </FormDataConsumer>
              ) : null}
            </>
          )}
        {isFieldAvailable('map_pos_x', props, RESOURCE_NAME) ? (
          <TextInput
            source='map_pos_x'
            validate={maxValue(100, 'Max value is 100')}
            fullWidth={true}
            label='Position in % from LEFT on country map'
          />
        ) : null}
        {isFieldAvailable('map_pos_y', props, RESOURCE_NAME) ? (
          <TextInput
            source='map_pos_y'
            validate={maxValue(100, 'Max value is 100')}
            fullWidth={true}
            label='Position in % from TOP on country'
          />
        ) : null}
        {isFieldAvailable('size_of_lin', props, RESOURCE_NAME) && (
          <TextInput source='size_of_lin' label='Length of pin in px' fullWidth={true} />
        )}
        {isFieldAvailable('site_url', props, RESOURCE_NAME) ? (
          <TextInput source='site_url' fullWidth={true} />
        ) : null}
      </SimpleForm>
    </Edit>
  );
};
