import React from "react";
import {
  Filter,
  List,
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  DateField,
  SearchInput,
  SelectInput,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { PostPagination } from "../../components/QueryHandlers";

const DISPLAYED_METHODS = {
  DELETE: "Delete",
  GET: "Download",
  POST: "Create",
  PUT: "Update",
};

const DISPLAYED_TYPES = {
  USER_ACTIVITY: "User Activity",
  IMPORT: "Import",
  FTP_IMAGE: "FTP Image",
};

const renderMethod = (record) => {
  return DISPLAYED_METHODS[record.method] || record.method;
};

const renderType = (record) => {
  return DISPLAYED_TYPES[record.type] || record.type;
};

const LogPanel = (props) => {
  return (
    <Show {...props} title=" ">
      <SimpleShowLayout>
        <JsonField
          source="message"
          label="Further information"
          addLabel={true}
          reactJsonOptions={{
            name: null,
            collapsed: false,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};

const LogFilters = (props) => {
  const { type } = props.filterValues;

  return (
    <Filter {...props}>
      <SearchInput source="q" disabled={!!type} alwaysOn />
      <SelectInput
        source="type"
        choices={[
          { id: "USER_ACTIVITY", name: "User Activity" },
          { id: "IMPORT", name: "Import" },
          { id: "FTP_IMAGE", name: "FTP Image" },
          { id: "PDF_GENERATE", name: "PDF GENERATE" },
        ]}
        alwaysOn
        defaultValue=""
      />
    </Filter>
  );
};

// rowClick="edit"
const LogList = (props) => {
  return (
    <List
      {...props}
      pagination={<PostPagination />}
      perPage={50}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<LogFilters />}
    >
      <Datagrid expand={<LogPanel />}>
        <TextField source="user.username" label="Username" />
        <TextField source="ip" />
        <FunctionField source="method" render={renderMethod} />
        <FunctionField source="type" render={renderType} />
        <TextField source="path" />
        <TextField source="documentId" />
        <DateField source="createdAt" label="Log recorded" showTime={true} />
      </Datagrid>
    </List>
  );
};

export default LogList;
