import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "category-relation";

export const CategoryRelationEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable("category", props, RESOURCE_NAME) && (
        <ReferenceInput
          label="Category"
          reference="categories"
          source="category"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteInput
            optionText={(categories = {}) => {
              if (categories == null) {
                return "";
              }
              const { region_code = "", sys_name = "" } = categories;
              return `${region_code} - ${sys_name}`;
            }}
          />
        </ReferenceInput>
      )}
      {isFieldAvailable("sub_categories", props, RESOURCE_NAME) && (
        <ReferenceArrayInput
          label="SubCategory"
          reference="categories"
          source="sub_categories"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(categories = {}) => {
              if (categories == null) {
                return "";
              }
              const { region_code = "", sys_name = "" } = categories;
              return `${region_code} - ${sys_name}`;
            }}
          />
        </ReferenceArrayInput>
      )}
    </SimpleForm>
  </Edit>
);
