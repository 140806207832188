import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "main-grocery-chain";

export const MainGroceryChainEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable("name", props, RESOURCE_NAME) && (
        <TextInput source="name" fullWidth={true} />
      )}
      {isFieldAvailable("description", props, RESOURCE_NAME) && (
        <TextInput source="description" fullWidth={true} />
      )}
      {isFieldAvailable("percent", props, RESOURCE_NAME) && (
        <TextInput source="percent" fullWidth={true} />
      )}
      {isFieldAvailable("color", props, RESOURCE_NAME) && (
        <ColorInput source="color" fullWidth={true} />
      )}
      {isFieldAvailable("side_left", props, RESOURCE_NAME) && (
        <RadioButtonGroupInput
          source="side_left"
          label="Position"
          choices={[
            { id: true, name: "Left" },
            { id: false, name: "Right" },
          ]}
        />
      )}
      {isFieldAvailable("size_of_lin", props, RESOURCE_NAME) && (
        <TextInput source="size_of_lin" labe="LineSize" fullWidth={true} />
      )}
      {isFieldAvailable("sub_grocery_chains", props, RESOURCE_NAME) && (
        <ReferenceArrayInput
          fullWidth={true}
          label="Sub grocery chains"
          reference="sub-grocery-chains"
          source="sub_grocery_chains"
          filter={{ fetchMode: "reflist" }}
        >
          <AutocompleteArrayInput
            optionText={(chain = {}) => {
              if (chain == null) {
                return "";
              }
              const { region_code = "", name = "" } = chain;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceArrayInput>
      )}
      {isFieldAvailable("countries", props, RESOURCE_NAME) && (
        <ReferenceArrayInput
          fullWidth={true}
          label="Countries"
          reference="countries"
          source="countries"
          filter={{ fetchMode: "reflist" }}
        >
          <AutocompleteArrayInput
            optionText={(countries = {}) => {
              if (countries == null) {
                return "";
              }
              const { region_code = "", name = "" } = countries;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceArrayInput>
      )}
    </SimpleForm>
  </Edit>
);
