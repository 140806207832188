import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

export const SalesPricesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth={true} source="type" />
      <TextInput fullWidth={true} source="code" />
      <ReferenceInput
        fullWidth={true}
        label="Product"
        reference="products"
        source="product"
        filter={{ fetchMode: "reflist" }}
      >
        <AutocompleteInput
          optionText={(product = {}) => {
            if (product == null) {
              return "";
            }
            const { description_1 = "" } = product;
            return `${description_1}`;
          }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
