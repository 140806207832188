import React from "react";
import {
  Create,
  AutocompleteInput,
  SimpleForm,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const CategoryRelationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <ReferenceInput
        label="Category"
        reference="categories"
        source="category"
        filter={{ fetchMode: "reflist" }}
        fullWidth={true}
      >
        <AutocompleteInput
          optionText={(categories = {}) => {
            if (categories == null) {
              return "";
            }
            const { region_code = "", sys_name = "" } = categories;
            return `${region_code} - ${sys_name}`;
          }}
        />
      </ReferenceInput>
      <ReferenceArrayInput
        label="SubCategory"
        reference="categories"
        source="sub_categories"
        filter={{ fetchMode: "reflist" }}
        fullWidth={true}
      >
        <AutocompleteArrayInput
          optionText={(categories = {}) => {
            if (categories == null) {
              return "";
            }
            const { region_code = "", sys_name = "" } = categories;
            return `${region_code} - ${sys_name}`;
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
