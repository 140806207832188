const STORAGE_KEY_AUTH_TOKEN = 'token';
const STORAGE_KEY_ROLE_NAME = 'roleName';
const STORAGE_KEY_PERMISSIONS = 'permissions';
const STORAGE_KEY_REGION_CODE = 'regionCode';

export default function makeAuthProvider(url) {
    return {
        login: ({ username, password }) => {
            const request = new Request(url, {
                method: 'POST',
                body: JSON.stringify({ identifier: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' })
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(loginData => {
                    if (loginData.user.role.name === 'Admin' || loginData.user.role.name === 'User') {
                        localStorage.setItem(STORAGE_KEY_REGION_CODE, loginData.user.region_code)
                        localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, loginData.jwt);
                        localStorage.setItem(STORAGE_KEY_ROLE_NAME, loginData.user.role.name);
                        localStorage.setItem(STORAGE_KEY_PERMISSIONS, JSON.stringify(loginData.user.permissions.user));
                    }
                });
        },
        logout: () => {
            localStorage.removeItem(STORAGE_KEY_AUTH_TOKEN);
            localStorage.removeItem(STORAGE_KEY_PERMISSIONS);
            localStorage.removeItem(STORAGE_KEY_ROLE_NAME);
            return Promise.resolve();
        },
        checkAuth: params => {
            return localStorage.getItem(STORAGE_KEY_AUTH_TOKEN)
                ? Promise.resolve()
                : Promise.reject();
        },
        checkError: error => Promise.resolve(),
        getPermissions: () => {
            const role = localStorage.getItem(STORAGE_KEY_ROLE_NAME) || 'User';
            const attributePermissionsString = localStorage.getItem(STORAGE_KEY_PERMISSIONS) || '{}';
            return Promise.resolve({
                role,
                attributePermissions: JSON.parse(attributePermissionsString),
            });
        }
    };
}
