import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, BooleanInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
import { DIVISION_CHOICES, COUNTRY_CHOICES, LOCATION_CHOICES } from '../../../utils/constants';

export const TeamCreate = (props) => {
  return (
    <Create {...props} undoable={false}>
      <SimpleForm>
        <MediaUploader attribute='media' label='Image' accept='images/*' />
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
        <TextInput source='name' fullWidth={true} />
        <TextInput source='title' fullWidth={true} />
        <SelectInput source='division' choices={DIVISION_CHOICES} />
        <TextInput label='Phone (TLF)' source='phone' fullWidth={true} />
        <TextInput source='email' fullWidth={true} />
        <TextInput source='keywords' fullWidth={true} />
        <SelectInput source='country' choices={COUNTRY_CHOICES} />
        <SelectInput source='location' choices={LOCATION_CHOICES} />
        <BooleanInput label='Visible to all regions' source='show_to_all' />
      </SimpleForm>
    </Create>
  );
};
