import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import MediaUploader from "../../components/MediaUploader";
import { ColorInput } from "react-admin-color-input";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "sub-grocery-chain";

export const SubGroceryChainEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <MediaUploader attribute="logo" label="Logo" accept="images/*" />
      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable("name", props, RESOURCE_NAME) && (
        <TextInput source="name" fullWidth={true} />
      )}
      {isFieldAvailable("number", props, RESOURCE_NAME) && (
        <TextInput source="number" fullWidth={true} />
      )}
      {isFieldAvailable("description", props, RESOURCE_NAME) && (
        <TextInput source="description" fullWidth={true} />
      )}
      {isFieldAvailable("logo_bg_color", props, RESOURCE_NAME) && (
        <ColorInput source="logo_bg_color" fullWidth={true} />
      )}
      {isFieldAvailable("main_grocery_chain", props, RESOURCE_NAME) && (
        <ReferenceInput
          source="main_grocery_chain"
          reference="main-grocery-chains"
          filter={{ fetchMode: "reflist" }}
          allowEmpty
          label="Main Grocery Chain"
          fullWidth={true}
        >
          <AutocompleteInput
            optionText={(chain = {}) => {
              if (chain == null) {
                return "";
              }
              const { region_code = "", name = "" } = chain;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Edit>
);
