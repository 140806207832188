import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'certification';

export const CertificationEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('file', props, RESOURCE_NAME) && (
        <MediaUploader attribute='file' withVisibilityFeature />
      )}
      {isFieldAvailable('title', props, RESOURCE_NAME) && <TextInput source='title' fullWidth />}
      {isFieldAvailable('description', props, RESOURCE_NAME) && (
        <TextInput source='description' fullWidth />
      )}
      {isFieldAvailable('year', props, RESOURCE_NAME) && <TextInput source='year' fullWidth />}
      {isFieldAvailable('type', props, RESOURCE_NAME) && <TextInput source='type' fullWidth />}
    </SimpleForm>
  </Edit>
);
