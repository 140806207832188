import React from "react";
import { makeRegionChoices } from "../../../utils/regionSelector";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";

export const ContinentCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source="name" fullWidth={true} />
      <TextInput source="description" fullWidth={true} />
      <ReferenceArrayInput
        label="Suppliers"
        reference="suppliers"
        source="suppliers"
        filter={{ fetchMode: "reflist" }}
        fullWidth={true}
      >
        <AutocompleteArrayInput
          optionText={(supplier = {}) => {
            if (supplier == null) {
              return "";
            }
            const { region_code = "", name = "" } = supplier;
            return `${region_code} - ${name}`;
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
