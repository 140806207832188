import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField,  FunctionField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { ColorField } from 'react-admin-color-input';
import { useDispatch } from 'react-redux';
import { rangeHelp } from '../../help';

export const RangeList = props => {
    const dispatch = useDispatch();
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: rangeHelp }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="order" />
            <TextField source="name" />
            <TextField source="title" />
            <TextField source="description" />
            <ColorField source="color" />
            <FunctionField label="Background" render={record => {
                if (record && record.background) {
                    return '✔';
                }
                return '✖';
                }} />
                <FunctionField label="Icon" render={record => {
                if (record && record.icon) {
                    return '✔';
                }
                return '✖';
            }} />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
)
};