/* export const getProductOptionText0 = (record) => {
  if (record == null) {
    return '';
  }
  if (record && record.description_1) {
    if (record.art_nr_new) {
      return `${record.region_code} - ${record.art_nr} - ${record.description_1}`;
    }
    if (record.art_nr) {
      return `${record.region_code} - (${record.art_nr}!) - ${record.description_1}`;
    }
  }

  return '';
} */

export const getProductOptionText = (product = {}) => {
  if (product == null) {
    return '';
  }

  const { region_code = '', art_nr = '', art_nr_new = '', description_1 = '' } = product;

  return `${region_code} - ${art_nr_new || `(${art_nr}!)`} - ${description_1}`;
};
