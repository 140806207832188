import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput,
  required,
} from 'react-admin';
import { getProductOptionText } from '../../../utils/functions';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'fs-match';

export const FsMatchEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
          <SelectInput
            source='region_code'
            choices={makeRegionChoices()}
            validate={[required()]}
            fullWidth
          />
        ) : null}
        {isFieldAvailable('header', props, RESOURCE_NAME) ? (
          <TextInput source='header' fullWidth={true} />
        ) : null}
        {isFieldAvailable('product_txt', props, RESOURCE_NAME) ? (
          <TextInput source='product_txt' fullWidth={true} />
        ) : null}
        {isFieldAvailable('main_products', props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            label='Main Products'
            reference='products'
            source='main_products'
            filter={{ fetchMode: 'reflist', show_in_foodservice: true }}
            fullWidth={true}
          >
            <AutocompleteArrayInput optionText={getProductOptionText} />
          </ReferenceArrayInput>
        ) : null}
        {isFieldAvailable('matched_products', props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            label='Matched Products'
            reference='products'
            source='matched_products'
            filter={{ fetchMode: 'reflist', show_in_foodservice: true }}
            fullWidth={true}
          >
            <AutocompleteArrayInput optionText={getProductOptionText} />
          </ReferenceArrayInput>
        ) : null}
      </SimpleForm>
    </Edit>
  );
};
