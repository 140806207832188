import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  ArrayField,
  SingleFieldList,
  FunctionField,
} from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';

export const CategoryRelationList = (props) => {
  const dispatch = useDispatch();
  const content = `<h1>This is Category-Relations list page</h1><br>
    Here you can define the subcategory relations`;
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' } });
  });
  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='category.sys_name' />
        <ChipField label='Category RC' source='category.region_code' />
        <ArrayField
          label='SubCategories'
          reference='categories'
          source='sub_categories'
         filter={{ fetchMode: 'reflist' }}
        >
          <SingleFieldList linkType={false}>
            <FunctionField
              label='Own Background'
              render={(record) => {
                return <Chip label={`${record.order}: ${record.sys_name}`} />;
              }}
            />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};
