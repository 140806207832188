import React, { useEffect, useState } from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  BooleanInput,
  useDataProvider,
  AutocompleteArrayInput,
  AutocompleteInput,
  required,
  SelectInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import MediaUploader from '../../components/MediaUploader';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import DateInputWithSetter from '../../components/DateInputWithSetter';
import { makeRegionChoices } from '../../../utils/regionSelector';

const RESOURCE_NAME = 'product';
const FIELD_ID_BOBBLER = 'bobbler';

export const ProductEdit = (props) => {
  const [isBobblerOn, setIsBobblerOn] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getOne('products', { id: props.id })
      .then(({ data: record }) => {
        if (FIELD_ID_BOBBLER in record) {
          setIsBobblerOn(record[FIELD_ID_BOBBLER]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider, props.id]);

  useEffect(() => {
    const setCheckboxDisabled = (label) => {
      const labelContent = label
        .querySelector('.MuiFormControlLabel-label')
        .textContent.toLowerCase();
      if (labelContent !== 'organic eu') {
        label.classList.add('Mui-disabled');
        label.childNodes.forEach((child) => child.classList.add('Mui-disabled'));
        label.querySelector('input').disabled = true;
      }
    };

    const setCheckboxEditable = (label) => {
      const labelContent = label
        .querySelector('.MuiFormControlLabel-label')
        .textContent.toLowerCase();
      if (labelContent !== 'organic eu') {
        label.classList.remove('Mui-disabled');
        label.childNodes.forEach((child) => child.classList.remove('Mui-disabled'));
        label.querySelector('input').disabled = false;
      }
    };

    const consumerLabelCheckboxes = document.querySelectorAll(
      '#consumer_labels .MuiFormGroup-root label'
    );
    consumerLabelCheckboxes.forEach(isBobblerOn ? setCheckboxEditable : setCheckboxDisabled);
  }, [isBobblerOn]);

  return (
    <Edit {...props} undoable={false}>
      <TabbedForm>
        <FormTab label='Base Product'>
          {isFieldAvailable('pictures', props, RESOURCE_NAME) ? (
            <MediaUploader attribute='pictures' label='Pictures' multiple accept='images/*' />
          ) : null}
          {isFieldAvailable('art_nr', props, RESOURCE_NAME) ? (
            <TextInput
              label='ART.NO. (old)'
              disabled={!isBobblerOn}
              source='art_nr'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('art_nr_new', props, RESOURCE_NAME) ? (
            <TextInput
              label='ART.NO. (new)'
              disabled={!isBobblerOn}
              source='art_nr_new'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('description_1', props, RESOURCE_NAME) ? (
            <TextInput
              label='PRODUCT DESCRIPTION 1'
              source='description_1'
              disabled={!isBobblerOn}
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('description_2', props, RESOURCE_NAME) ? (
            <TextInput
              label='PRODUCT DESCRIPTION 2'
              source='description_2'
              disabled={!isBobblerOn}
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('description_3', props, RESOURCE_NAME) ? (
            <TextInput label='PRODUCT DESCRIPTION 3' source='description_3' fullWidth={true} />
          ) : null}
          {isFieldAvailable('text', props, RESOURCE_NAME) ? (
            <RichTextInput label='PRODUCT TXT' source='text' fullWidth={true} />
          ) : null}
          {isFieldAvailable('show_in_foodservice', props, RESOURCE_NAME) ? (
            <BooleanInput source='show_in_foodservice' label='SHOW IN FoodService' />
          ) : null}
          {isFieldAvailable('temperature', props, RESOURCE_NAME) ? (
            <ReferenceInput
              source='temperature'
              reference='temperatures'
              allowEmpty
              label='TEMPERATURES'
              fullWidth={true}
            >
              <AutocompleteInput optionText='name' disabled={!isBobblerOn} />
            </ReferenceInput>
          ) : null}
          {isFieldAvailable('customers', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              resource='customers'
              source='customers'
              reference='customers'
              filter={{ fetchMode: 'reflist' }}
              perPage={Number.MAX_SAFE_INTEGER}
              allowEmpty
              label='ONLY SHOW PRODUCT'
              fullWidth={true}
            >
              <CheckboxGroupInput
                optionText={(customer = {}) => {
                  if (customer !== null) {
                    const { region_code = '', name = '' } = customer;
                    return `${region_code} - ${name}`;
                  }

                  return '';
                }}
              />
            </ReferenceArrayInput>
          ) : null}
          {isFieldAvailable('netto_weight', props, RESOURCE_NAME) ? (
            <TextInput source='netto_weight' disabled={!isBobblerOn} fullWidth={true} />
          ) : null}
          {isFieldAvailable('number_package_pr', props, RESOURCE_NAME) ? (
            <TextInput source='number_package_pr' fullWidth={true} disabled={!isBobblerOn} />
          ) : null}
          {isFieldAvailable('number_package', props, RESOURCE_NAME) ? (
            <TextInput source='number_package' disabled={!isBobblerOn} fullWidth={true} />
          ) : null}
          {isFieldAvailable('layers_pallet', props, RESOURCE_NAME) ? (
            <TextInput source='layers_pallet' fullWidth={true} disabled={!isBobblerOn} />
          ) : null}
          {isFieldAvailable('days_of_durability', props, RESOURCE_NAME) ? (
            <TextInput source='days_of_durability' disabled={!isBobblerOn} fullWidth={true} />
          ) : null}
          {isFieldAvailable('min_packaging_print', props, RESOURCE_NAME) ? (
            <TextInput source='min_packaging_print' disabled={!isBobblerOn} fullWidth={true} />
          ) : null}
          {isFieldAvailable('min_batch', props, RESOURCE_NAME) ? (
            <TextInput source='min_batch' disabled={!isBobblerOn} fullWidth={true} />
          ) : null}
          {isFieldAvailable('ean_number', props, RESOURCE_NAME) ? (
            <TextInput source='ean_number' disabled={!isBobblerOn} fullWidth={true} />
          ) : null}

          {isFieldAvailable('validated', props, RESOURCE_NAME) ? (
            <BooleanInput source='validated' />
          ) : null}
          {isFieldAvailable(FIELD_ID_BOBBLER, props, RESOURCE_NAME) ? (
            <BooleanInput
              source={FIELD_ID_BOBBLER}
              onChange={(newValue) => {
                setIsBobblerOn(newValue);
              }}
            />
          ) : null}
          {isFieldAvailable('double_stacked', props, RESOURCE_NAME) ? (
            <BooleanInput source='double_stacked' disabled={!isBobblerOn} />
          ) : null}
          {isFieldAvailable('ean_hide', props, RESOURCE_NAME) ? (
            <BooleanInput source='ean_hide' />
          ) : null}
          {isFieldAvailable('overwrite', props, RESOURCE_NAME) ? (
            <BooleanInput source='overwrite' />
          ) : null}
          {isFieldAvailable('supplier_nav_id', props, RESOURCE_NAME) ? (
            <TextInput disabled={!isBobblerOn} source='supplier_nav_id' fullWidth={true} />
          ) : null}
          {isFieldAvailable('country_code', props, RESOURCE_NAME) ? (
            <TextInput disabled={!isBobblerOn} source='country_code' fullWidth={true} />
          ) : null}
          {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
            <SelectInput
              source='region_code'
              choices={makeRegionChoices()}
              validate={[required()]}
              fullWidth
            />
          ) : null}
          {isFieldAvailable('new_until', props, RESOURCE_NAME) ? (
            <DateInputWithSetter attribute='new_until' customLabel='Renew' />
          ) : null}
          {isFieldAvailable('bobbler_supplier', props, RESOURCE_NAME) ? (
            <BooleanInput source='bobbler_supplier' label='Lock the supplier' />
          ) : null}
          {isFieldAvailable('supplier', props, RESOURCE_NAME) ? (
            <ReferenceInput
              resource='supplier'
              source='supplier'
              reference='suppliers'
              allowEmpty
              label='CHOOSE SUPPLIER'
              filter={{ fetchMode: 'reflist' }}
              fullWidth={true}
            >
              <AutocompleteInput
                optionText={(supplier = {}) => {
                  if (supplier == null) {
                    return '';
                  }

                  const { region_code = '', name = '' } = supplier;
                  return `${region_code} - ${name}`;
                }}
                disabled={!isBobblerOn}
              />
            </ReferenceInput>
          ) : null}
          {isFieldAvailable('series_item', props, RESOURCE_NAME) ? (
            <ReferenceInput
              resource='series_item'
              source='series_item'
              reference='series-items'
              filter={{ fetchMode: 'reflist' }}
              allowEmpty={false}
              label='CHOOSE SERIES ITEM'
              fullWidth={true}
            >
              <AutocompleteInput
                optionText={(series = {}) => {
                  if (series == null) {
                    return '';
                  }
                  const { region_code = '', header = '' } = series;
                  return `${region_code} - ${header}`;
                }}
              />
            </ReferenceInput>
          ) : null}
          {isFieldAvailable('brand', props, RESOURCE_NAME) ? (
            <ReferenceInput
              source='brand'
              reference='brands'
              filter={{ fetchMode: 'reflist' }}
              allowEmpty
              label='CHOOSE BRAND'
              fullWidth={true}
            >
              <AutocompleteInput
                optionText={(brand = {}) => {
                  if (brand == null) {
                    return '';
                  }
                  const { region_code = '', name = '' } = brand;
                  return `${region_code} - ${name}`;
                }}
                disabled={!isBobblerOn}
              />
            </ReferenceInput>
          ) : null}
          {isFieldAvailable('range', props, RESOURCE_NAME) ? (
            <ReferenceInput
              source='range'
              reference='ranges'
              filter={{ fetchMode: 'reflist' }}
              allowEmpty
              label='CHOOSE RANGE'
              fullWidth={true}
            >
              <AutocompleteInput
                optionText={(range = {}) => {
                  if (range == null) {
                    return '';
                  }
                  const { region_code = '', name = '' } = range;
                  return `${region_code} - ${name}`;
                }}
                disabled={!isBobblerOn}
              />
            </ReferenceInput>
          ) : null}
          {isFieldAvailable('categories', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              label='Categories'
              reference='categories'
              source='categories'
              filter={{ fetchMode: 'reflist' }}
              fullWidth={true}
            >
              <AutocompleteArrayInput
                optionText={(categories = {}) => {
                  if (categories == null) {
                    return '';
                  }
                  const { region_code = '', sys_name = '' } = categories;
                  return `${region_code} - ${sys_name}`;
                }}
              />
            </ReferenceArrayInput>
          ) : null}
          {isFieldAvailable('languages', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              resource='languages'
              source='languages'
              reference='languages'
              filter={{ fetchMode: 'reflist' }}
              perPage={Number.MAX_SAFE_INTEGER}
              allowEmpty
              label='LANGUAGES ON PACKAGING'
              fullWidth={true}
            >
              <CheckboxGroupInput
                optionText={(language = {}) => {
                  if (language == null) {
                    return '';
                  }
                  const { region_code = '', name = '' } = language;
                  return `${region_code} - ${name}`;
                }}
              />
            </ReferenceArrayInput>
          ) : null}
          {isFieldAvailable('retailers', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              label='Retailers'
              reference='retailers'
              source='retailers'
              filter={{ fetchMode: 'reflist' }}
              fullWidth={true}
            >
              <AutocompleteArrayInput
                optionText={(retailer = {}) => {
                  if (retailer == null) {
                    return '';
                  }
                  const { region_code = '', name = '' } = retailer;
                  return `${region_code} - ${name}`;
                }}
              />
            </ReferenceArrayInput>
          ) : null}
          {isFieldAvailable('consumer_labels', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              resource='consumer_labels'
              source='consumer_labels'
              reference='consumer-labels'
              filter={{ fetchMode: 'reflist' }}
              perPage={Number.MAX_SAFE_INTEGER}
              allowEmpty
              label='CONSUMER LABELING ON PACKAGING'
              fullWidth={true}
            >
              <CheckboxGroupInput
                optionText={(label = {}) => {
                  if (label == null) {
                    return '';
                  }
                  const { region_code = '', name = '' } = label;
                  return `${region_code} - ${name}`;
                }}
                optionValue='id'
              />
            </ReferenceArrayInput>
          ) : null}
        </FormTab>
        <FormTab label='FoodService'>
          {isFieldAvailable('fs_pictures', props, RESOURCE_NAME) ? (
            <MediaUploader
              attribute='fs_pictures'
              label='FoodService Pictures'
              multiple
              accept='images/*'
            />
          ) : null}
          {isFieldAvailable('fs_description_1', props, RESOURCE_NAME) ? (
            <TextInput
              label='FS PRODUCT DESCRIPTION 1'
              source='fs_description_1'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('ingredients', props, RESOURCE_NAME) ? (
            <TextInput source='ingredients' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput source='nutritional_values.energy.kj' label='Energy (kJ)' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput
              source='nutritional_values.energy.kcal'
              label='Energy (kCal)'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput source='nutritional_values.fat.total' label='Fat' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput
              source='nutritional_values.fat.saturated'
              label='Fat - Saturated'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput
              source='nutritional_values.carbohydrates.total'
              label='Carbohydrates'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput
              source='nutritional_values.carbohydrates.sugar'
              label='Carbohydrates - Sugar'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput source='nutritional_values.fibers' label='Fibers' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput source='nutritional_values.proteins' label='Proteins' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput source='nutritional_values.salt' label='Salt' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput
              source='nutritional_values.dry_matter'
              label='Dry Matter (%)'
              fullWidth={true}
            />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <TextInput source='nutritional_values.milk_fat' label='Milk fat' fullWidth={true} />
          ) : null}
          {isFieldAvailable('nutritional_values', props, RESOURCE_NAME) ? (
            <BooleanInput source='nutritional_values.homogenized' label='Homogenized' />
          ) : null}
          {isFieldAvailable('cooking', props, RESOURCE_NAME) ? (
            <TextInput source='cooking' fullWidth={true} />
          ) : null}
          {isFieldAvailable('storytelling', props, RESOURCE_NAME) ? (
            <TextInput source='storytelling' fullWidth={true} />
          ) : null}
          {isFieldAvailable('fs_validated', props, RESOURCE_NAME) ? (
            <BooleanInput source='fs_validated' />
          ) : null}
          {isFieldAvailable('fs_matches_main', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              label='FS Matches (Main)'
              reference='fs-matches'
              source='fs_matches_main'
              filter={{ fetchMode: 'reflist' }}
              fullWidth={true}
            >
              <AutocompleteArrayInput
                optionText={(fs_match = {}) => {
                  if (fs_match == null) {
                    return '';
                  }
                  const { region_code = '', header = '' } = fs_match;
                  return `${region_code} - ${header}`;
                }}
              />
            </ReferenceArrayInput>
          ) : null}
          {isFieldAvailable('fs_matches_matched', props, RESOURCE_NAME) ? (
            <ReferenceArrayInput
              label='FS Matches (Matched)'
              reference='fs-matches'
              source='fs_matches_matched'
              filter={{ fetchMode: 'reflist' }}
              fullWidth={true}
            >
              <AutocompleteArrayInput
                optionText={(fs_match = {}) => {
                  if (fs_match == null) {
                    return '';
                  }
                  const { region_code = '', header = '' } = fs_match;
                  return `${region_code} - ${header}`;
                }}
              />
            </ReferenceArrayInput>
          ) : null}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
