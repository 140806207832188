import React, { useEffect } from 'react';
import { List, TextField, DateField, Datagrid } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';

export const TranslationList = props => {
  const dispatch = useDispatch();
  const content = `<h1>Translation list</h1><br>Here you can set the texts for the frontends`
  useEffect(() => {
  dispatch({ type: 'HELP_MESSAGE', payload: { content }})
  dispatch({ type: 'HELP_IMAGE', payload: { content: '' }})
})
  return (
  <List {...props} pagination={<PostPagination />} filters={<PostFilter />}  perPage={50}>
    <Datagrid rowClick="edit">
      <TextField source="translate_key" />
      <TextField source="translate_dk" />
      <TextField source="translate_no" />
      <TextField source="translate_se" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)
};
