import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, BooleanInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { DIVISION_CHOICES, COUNTRY_CHOICES, LOCATION_CHOICES } from '../../../utils/constants';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'team';

export const TeamEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('media', props, RESOURCE_NAME) && (
        <MediaUploader attribute='media' label='Image' accept='images/*' />
      )}
      {isFieldAvailable('name', props, RESOURCE_NAME) && (
        <TextInput source='name' fullWidth={true} />
      )}
      {isFieldAvailable('title', props, RESOURCE_NAME) && (
        <TextInput source='title' fullWidth={true} />
      )}
      {isFieldAvailable('division', props, RESOURCE_NAME) && (
        <SelectInput source='division' choices={DIVISION_CHOICES} />
      )}
      {isFieldAvailable('phone', props, RESOURCE_NAME) && (
        <TextInput label='Phone (TLF)' source='phone' fullWidth={true} />
      )}
      {isFieldAvailable('email', props, RESOURCE_NAME) && (
        <TextInput source='email' fullWidth={true} />
      )}
      {isFieldAvailable('keywords', props, RESOURCE_NAME) && (
        <TextInput source='keywords' fullWidth={true} />
      )}
      {isFieldAvailable('country', props, RESOURCE_NAME) && (
        <SelectInput source='country' choices={COUNTRY_CHOICES} />
      )}
      {isFieldAvailable('location', props, RESOURCE_NAME) && (
        <SelectInput source='location' choices={LOCATION_CHOICES} />
      )}
      {isFieldAvailable('show_to_all', props, RESOURCE_NAME) && (
        <BooleanInput label='Visible to all regions' source='show_to_all' />
      )}
    </SimpleForm>
  </Edit>
);
