/** @format */

import React from "react";
import Menu from "./render/components/Menu";
import { Admin, Resource } from "react-admin";
import makeDataProvider from "./providers/data_provider";
import makeAuthProvider from "./providers/auth_provider";
import {
  ProductList,
  ProductEdit,
  ProductCreate,
} from "./render/views/products";
import {
  SupplierList,
  SupplierEdit,
  SupplierCreate,
} from "./render/views/suppliers";
import {
  SeriesItemList,
  SeriesItemEdit,
  SeriesItemCreate,
} from "./render/views/series-items";
import {
  FsMatchList,
  FsMatchEdit,
  FsMatchCreate,
} from "./render/views/fs-matches";
import {
  TemperatureList,
  TemperatureEdit,
  TemperatureCreate,
} from "./render/views/temperatures";
import {
  CustomerList,
  CustomerEdit,
  CustomerCreate,
} from "./render/views/customers";
import { BrandList, BrandEdit, BrandCreate } from "./render/views/brands";
import { RangeList, RangeEdit, RangeCreate } from "./render/views/ranges";
import {
  CategoryList,
  CategoryCreate,
  CategoryEdit,
} from "./render/views/categories";
import {
  LanguageList,
  LanguageCreate,
  LanguageEdit,
} from "./render/views/languages";
import {
  ConsumerLabelList,
  ConsumerLabelEdit,
  ConsumerLabelCreate,
} from "./render/views/consumer-labels";
import {
  RetailerList,
  RetailerCreate,
  RetailerEdit,
} from "./render/views/retailers";
import {
  ContinentList,
  ContinentCreate,
  ContinentEdit,
} from "./render/views/continents";
import { GateKeeperList } from "./render/views/gatekeepers";
import {
  CountryList,
  CountryCreate,
  CountryEdit,
} from "./render/views/countries";
import {
  WarehouseAttributesList,
  WarehouseAttributesCreate,
  WarehouseAttributesEdit,
} from "./render/views/warehouse-attributes";
import CustomRoutes from "./render/components/CustomRoutes";
import {
  TranslationList,
  TranslationEdit,
  TranslationCreate,
} from "./render/views/translations";
import { ApiDetailsProvider } from "./providers/ApiDetailsContext";
import { MedieaLibraryList } from "./render/views/medialibrary/list";
import {
  CategoryRelationList,
  CategoryRelationCreate,
  CategoryRelationEdit,
} from "./render/views/category-relations";
import {
  CertificationList,
  CertificationEdit,
  CertificationCreate,
} from "./render/views/certifications";
import {
  ConceptList,
  ConceptEdit,
  ConceptCreate,
} from "./render/views/concept";
import { CsrList, CsrEdit, CsrCreate } from "./render/views/csrs";
import {
  LogisticMediaList,
  LogisticMediaCreate,
  LogisticMediaEdit,
} from "./render/views/logistic-media";
import { TeamList, TeamEdit, TeamCreate } from "./render/views/teams";
import helpTextReducer from "./helpTextReducer";
import helpImageReducer from "./helpImageReducer";
import GeiaLayout from "./render/layout/GeiaLayout";
import {
  SalesPricesCreate,
  SalesPricesEdit,
  SalesPricesList,
} from "./render/views/salesprice";
import { UserCreate, UserEdit, UserList } from "./render/views/users";
import {
  MainGroceryChainCreate,
  MainGroceryChainEdit,
  MainGroceryChainList,
} from "./render/views/main-grocery-chains";
import {
  SubGroceryChainCreate,
  SubGroceryChainEdit,
  SubGroceryChainList,
} from "./render/views/sub-grocery-chains";
import {
  VisionsList,
  VisionsCreate,
  VisionsEdit,
} from "./render/views/visions";
import { ValuesList, ValuesCreate, ValuesEdit } from "./render/views/values";
import LogList from "./render/views/logs/list";
import EnvironmentHeader from "./render/components/EnvironmentHeader";

const authUrl = process.env.REACT_APP_AUTH_URL;
// const dataUrl = process.env.REACT_APP_DATA_URL;

const App = () => (
  <>
    <EnvironmentHeader />
    <ApiDetailsProvider>
      <Admin
        dataProvider={makeDataProvider([
          "upload",
          "pictures",
          "fs_pictures",
          "media",
          "logo",
          "background",
          "file",
          "pdf",
          "icon",
        ])}
        authProvider={makeAuthProvider(authUrl)}
        menu={Menu}
        customRoutes={CustomRoutes}
        customReducers={{
          helpText: helpTextReducer,
          helpImage: helpImageReducer,
        }}
        layout={GeiaLayout}
      >
        {(permissions) => [
          <Resource
            name="products"
            list={ProductList}
            edit={ProductEdit}
            create={ProductCreate}
          />,
          <Resource
            name="temperatures"
            list={TemperatureList}
            edit={TemperatureEdit}
            create={TemperatureCreate}
          />,
          <Resource
            name="series-items"
            list={SeriesItemList}
            create={SeriesItemCreate}
            edit={SeriesItemEdit}
          />,
          <Resource
            name="fs-matches"
            list={FsMatchList}
            create={FsMatchCreate}
            edit={FsMatchEdit}
          />,
          <Resource
            name="customers"
            list={CustomerList}
            create={CustomerCreate}
            edit={CustomerEdit}
          />,
          <Resource
            name="suppliers"
            list={SupplierList}
            create={SupplierCreate}
            edit={SupplierEdit}
          />,
          <Resource
            name="brands"
            list={BrandList}
            create={BrandCreate}
            edit={BrandEdit}
          />,
          <Resource
            name="ranges"
            list={RangeList}
            create={RangeCreate}
            edit={RangeEdit}
          />,
          <Resource
            name="categories"
            list={CategoryList}
            create={CategoryCreate}
            edit={CategoryEdit}
          />,
          <Resource
            name="category-relations"
            list={CategoryRelationList}
            create={CategoryRelationCreate}
            edit={CategoryRelationEdit}
          />,
          <Resource
            name="salesprices"
            list={SalesPricesList}
            create={SalesPricesCreate}
            edit={SalesPricesEdit}
          />,
          <Resource
            name="languages"
            list={LanguageList}
            create={LanguageCreate}
            edit={LanguageEdit}
          />,
          <Resource
            name="consumer-labels"
            list={ConsumerLabelList}
            create={ConsumerLabelCreate}
            edit={ConsumerLabelEdit}
          />,
          <Resource
            name="retailers"
            list={RetailerList}
            create={RetailerCreate}
            edit={RetailerEdit}
          />,
          <Resource
            name="main-grocery-chains"
            list={MainGroceryChainList}
            edit={MainGroceryChainEdit}
            create={MainGroceryChainCreate}
          />,
          <Resource
            name="sub-grocery-chains"
            list={SubGroceryChainList}
            edit={SubGroceryChainEdit}
            create={SubGroceryChainCreate}
          />,
          <Resource
            name="continents"
            list={ContinentList}
            create={ContinentCreate}
            edit={ContinentEdit}
          />,
          <Resource
            name="countries"
            list={CountryList}
            create={CountryCreate}
            edit={CountryEdit}
          />,
          <Resource
            name="translations"
            list={TranslationList}
            create={TranslationCreate}
            edit={TranslationEdit}
          />,
          <Resource
            name="logistic-medias"
            list={LogisticMediaList}
            create={LogisticMediaCreate}
            edit={LogisticMediaEdit}
          />,
          <Resource
            name="visions"
            list={VisionsList}
            create={VisionsCreate}
            edit={VisionsEdit}
          />,
          <Resource
            name="values"
            list={ValuesList}
            create={ValuesCreate}
            edit={ValuesEdit}
          />,
          <Resource name="navison-imports" />,
          <Resource name="gatekeepers" list={GateKeeperList} />,
          <Resource name="upload" />,
          <Resource
            name="concepts"
            list={ConceptList}
            create={ConceptCreate}
            edit={ConceptEdit}
          />,
          <Resource
            name="certifications"
            list={CertificationList}
            create={CertificationCreate}
            edit={CertificationEdit}
          />,
          <Resource
            name="csrs"
            list={CsrList}
            create={CsrCreate}
            edit={CsrEdit}
          />,
          <Resource
            name="teams"
            list={TeamList}
            create={TeamCreate}
            edit={TeamEdit}
          />,
          <Resource
            name="warehouse-attributions"
            list={WarehouseAttributesList}
            create={WarehouseAttributesCreate}
            edit={WarehouseAttributesEdit}
          />,
          <Resource name="medialibrary" list={MedieaLibraryList} />,
          permissions.role === "Admin" ? (
            <Resource name="logs" list={LogList} />
          ) : null,
          permissions.role === "Admin" ? <Resource name="permissions" /> : null,
          permissions.role === "Admin" ? (
            <Resource
              name="users"
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
            />
          ) : null,
        ]}
      </Admin>
    </ApiDetailsProvider>
  </>
);

export default App;
