import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, ArrayField, SingleFieldList, ChipField, FunctionField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { categoryHelp } from '../../help';

export const CategoryList = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'HELP_MESSAGE', payload: { content: '' } })
        dispatch({ type: 'HELP_IMAGE', payload: { content: categoryHelp } })
    })
    return (
        <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
            <Datagrid rowClick="edit">
                <TextField source="region_code" />
                <TextField source="order" />
                <TextField source="name" />
                <TextField source="sys_name" />
                <TextField source="title" />
                <ArrayField source="ranges" fieldKey="id">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="ranges" label="Range RC" fieldKey="id">
                    <SingleFieldList linkType={false}>
                        <ChipField source="region_code" />
                    </SingleFieldList>
                </ArrayField>
                <FunctionField label="Own Background" render={record => {
                    if (record && record.background) {
                        return '✔';
                    }
                    return '✖';
                }} />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </Datagrid>
        </List>
    )
};