import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';

export const ValuesList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' } });
  });

  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='order' />
        <TextField source='description' />
        <FunctionField
          label='Image'
          render={(record) => {
            if (record.logo) {
              return '✔';
            }
            return '✖';
          }}
        />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};
