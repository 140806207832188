import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';

export const CertificationCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={[
          { id: 'DK', name: 'DK' },
          { id: 'SE', name: 'SE' },
          { id: 'NO', name: 'NO' },
        ]}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader attribute='file' withVisibilityFeature />
      <TextInput source='title' fullWidth />
      <TextInput source='description' fullWidth />
      <TextInput source='year' fullWidth />
      <TextInput source='type' fullWidth />
    </SimpleForm>
  </Create>
);
