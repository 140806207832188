import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import isFieldAvailable from '../../../utils/isFieldAvailable';
const RESOURCE_NAME = 'translation';

export const TranslationEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          {isFieldAvailable('translate_key', props, RESOURCE_NAME) && <TextInput source="translate_key" fullWidth={true} />}
          {isFieldAvailable('translate_dk', props, RESOURCE_NAME) && <TextInput source="translate_dk" fullWidth={true} />}
          {isFieldAvailable('translate_no', props, RESOURCE_NAME) && <TextInput source="translate_no" fullWidth={true} />}
          {isFieldAvailable('translate_se', props, RESOURCE_NAME) && <TextInput source="translate_se" fullWidth={true} />}
      </SimpleForm>
  </Edit>
);