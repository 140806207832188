import React from "react";
import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import MediaUploader from "../../components/MediaUploader";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "country";

export const CountryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable("background", props, RESOURCE_NAME) && (
        <MediaUploader
          attribute="background"
          label="Background"
          accept="images/*"
        />
      )}
      {isFieldAvailable("name", props, RESOURCE_NAME) ? (
        <TextInput source="name" fullWidth={true} />
      ) : null}
      {isFieldAvailable("description", props, RESOURCE_NAME) && (
        <TextInput source="description" fullWidth={true} />
      )}
      <TextInput source="code" fullWidth={true} />
      {isFieldAvailable("continent", props, RESOURCE_NAME) ? (
        <ReferenceInput
          source="continent"
          reference="continents"
          filter={{ fetchMode: "reflist" }}
          label="Continent"
          fullWidth={true}
        >
          <SelectInput
            optionText={(supplier = {}) => {
              if (supplier == null) {
                return "";
              }
              const { region_code = "", name = "" } = supplier;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceInput>
      ) : null}
      {isFieldAvailable("suppliers", props, RESOURCE_NAME) ? (
        <ReferenceArrayInput
          label="Suppliers"
          reference="suppliers"
          source="suppliers"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(supplier = {}) => {
              if (supplier == null) {
                return "";
              }
              const { region_code = "", name = "" } = supplier;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceArrayInput>
      ) : null}
    </SimpleForm>
  </Edit>
);
