const isFieldAvailable = (fieldName, props, resourceName) => {
    if (props && props.permissions) {
        const { permissions } = props;
        const { attributePermissions } = permissions;
        const fieldIsVisible = attributePermissions[resourceName] && attributePermissions[resourceName][fieldName];
        const godMode = permissions.role.toLowerCase() === 'admin';
        return fieldIsVisible || godMode;
    }
};

export default isFieldAvailable;