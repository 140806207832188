export const DIVISION_CHOICES = [
  { id: "sales", name: "Sales" },
  { id: "purchase", name: "Purchase" },
];

export const COUNTRY_CHOICES = [
  { id: "denmark", name: "Denmark" },
  { id: "norway", name: "Norway" },
  { id: "finland", name: "Finland" },
  { id: "sweden", name: "Sweden" },
];

export const LOCATION_CHOICES = [
  { id: "skaelskor", name: "Skælskør" },
  { id: "aalborg", name: "Aalborg" },
];

export const REGION_CODES = [
  { id: "DK", name: "DK" },
  { id: "SE", name: "SE" },
  { id: "NO", name: "NO" },
];

export const LOCAL_ENV = "local.development";
export const DEV_ENV = "development";
export const STAGING_ENV = "staging";
export const PROD_OLD_ENV = "prod_old";

export const LOCAL_ENV_HEADER = "Development environment";
export const DEV_ENV_HEADER = "Test environment";
export const STAGING_ENV_HEADER = "Geia admin Test environment";
export const PROD_OLD_ENV_HEADER = "Geia admin old production";
