import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, FunctionField, SelectField, BooleanField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { COUNTRY_CHOICES, DIVISION_CHOICES } from '../../../utils/constants';

export const TeamList = props => {
  const dispatch = useDispatch();
  const content = `<h1>This is Team members list page</h1><br>`
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content } })
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' } })
  })

  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="region_code" />
        <TextField source="name" />
        <TextField source="title" />
        <SelectField source="division" choices={DIVISION_CHOICES} />
        <FunctionField label="Image" sortBy="media" render={record => {
          if (record && record.media) {
            return '✔';
          }
          return '✖';
        }} />
        <SelectField source="country" choices={COUNTRY_CHOICES} />
        <BooleanField source="show_to_all" label="Visible to all regions" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  )
};
