import React from 'react'
import {Edit, SimpleForm, TextInput, SelectInput, required} from 'react-admin'
import isFieldAvailable from '../../../utils/isFieldAvailable';
import {ColorInput} from 'react-admin-color-input';
import { makeRegionChoices } from '../../../utils/regionSelector';

const RESOURCE_NAME = 'temperature';

export const TemperatureEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          {isFieldAvailable('region_code', props, RESOURCE_NAME) ? <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth /> : null}
          {isFieldAvailable('name', props, RESOURCE_NAME) ? <TextInput source="name" fullWidth={true} /> : null}
          {isFieldAvailable('color', props, RESOURCE_NAME) ? <ColorInput source="color" fullWidth={true} /> : null}
      </SimpleForm>
  </Edit>
);