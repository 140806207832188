import React, { cloneElement, useEffect, useState } from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  SearchInput,
  SelectInput,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  useRefresh,
} from 'react-admin';
import { Button } from 'ra-ui-materialui';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDispatch } from 'react-redux';

import { PostPagination } from '../../components/QueryHandlers';

const DATA_URL = process.env.REACT_APP_DATA_URL;

const TYPES = ['products (Base)', 'products (FoodService)', 'retailers', 'suppliers'];

const TYPE_CHOICES = TYPES.map((t) => {
  return { id: t, name: t };
});

const GatekeeperFilters = (props) => {
  const { refreshing } = props;

  return (
    <Filter {...props}>
      <SearchInput source='q' disabled={refreshing} alwaysOn />
      <SelectInput
        source='type'
        choices={TYPE_CHOICES}
        disabled={refreshing}
        alwaysOn
        defaultValue=''
      />
    </Filter>
  );
};

const GatekeeperActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  refreshing,
  setRefreshing,
  ...rest
}) => {
  const onClickRefresh = async () => {
    const token = localStorage.getItem('token');
    const method = 'GET';
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const url = `${DATA_URL}/gatekeepers/refresh`;

    setRefreshing(true);
    await fetch(url, { method, headers });
    setRefreshing(false);
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <Button
        disabled={refreshing}
        label='Refresh'
        startIcon={<RefreshIcon />}
        variant='contained'
        color='primary'
        onClick={onClickRefresh}
      />
      <ExportButton
        disabled={total === 0 || refreshing}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const getClickPath = (record) => {
  const { type = 'products' } = record || {};

  const entityRegExp = new RegExp(/[^ ()]*/);

  let entity = type.match(entityRegExp)[0];

  return `/${entity}/${JSON.parse(record.description_2).product_id}`;
};

export const GateKeeperList = (props) => {
  const [refreshing, setRefreshing] = useState(null);
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const content = `<h1>Gatekeeper list</h1><br>You can check here if some data or content is missing`;
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' } });
  });

  useEffect(() => {
    if (refreshing === false) {
      refresh();
    }
  }, [refreshing, refresh]);

  return (
    <List
      {...props}
      pagination={<PostPagination />}
      filters={<GatekeeperFilters refreshing={refreshing} />}
      actions={<GatekeeperActions refreshing={refreshing} setRefreshing={setRefreshing} />}
    >
      <Datagrid rowClick={(id, basepath, record) => getClickPath(record)}>
        <TextField source='region_code' />
        <TextField source='type' />
        <TextField source='description_1' />
        <TextField label='Art.No. (new)' source='art_nr' />
        <FunctionField
          label='Problems'
          render={(record) => {
            const parsedDescription2 = JSON.parse(record.description_2);
            let description2 = 'Missing ';

            Object.keys(parsedDescription2).forEach((key) => {
              if (key !== 'product_id') {
                description2 = `${description2}${key}, `;
              }
            });
            return description2;
          }}
        />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};
