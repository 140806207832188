import React from 'react';
import MediaUploader from '../../components/MediaUploader';
import { ColorInput } from 'react-admin-color-input';
import { makeRegionChoices } from '../../../utils/regionSelector';
import { Create, SimpleForm, NumberInput, TextInput, SelectInput, required } from 'react-admin';

export const ConsumerLabelCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader attribute='logo' label='Logo' accept='images/*' />
      <TextInput source='name' fullWidth={true} />
      <ColorInput source='color' fullWidth={true} />
      <NumberInput min='1' source='order' fullWidth={true} />
    </SimpleForm>
  </Create>
);
