import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const CsrCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader attribute='pdf' withVisibilityFeature label='PDF documents' />
      <MediaUploader attribute='pictures' multiple withVisibilityFeature label='Pictures' />
      <TextInput source='title' fullWidth />
      <TextInput source='description' fullWidth />
      <TextInput source='year' fullWidth />
    </SimpleForm>
  </Create>
);
