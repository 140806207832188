import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "continent";

export const ContinentEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable("name", props, RESOURCE_NAME) ? (
        <TextInput source="name" fullWidth={true} />
      ) : null}
      {isFieldAvailable("description", props, RESOURCE_NAME) ? (
        <TextInput source="description" fullWidth={true} />
      ) : null}
      {isFieldAvailable("suppliers", props, RESOURCE_NAME) ? (
        <ReferenceArrayInput
          label="Suppliers"
          reference="suppliers"
          source="suppliers"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(supplier = {}) => {
              if (supplier == null) {
                return "";
              }
              const { region_code = "", name = "" } = supplier;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceArrayInput>
      ) : null}
    </SimpleForm>
  </Edit>
);
