import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";
import MediaUploader from "../../components/MediaUploader";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const RetailerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source="name" fullWidth={true} />
      <MediaUploader attribute="logo" label="Logo" multiple accept="images/*" />
      <ReferenceArrayInput
        label="Products"
        reference="products"
        source="products"
        filter={{ fetchMode: "reflist" }}
        fullWidth={true}
      >
        <AutocompleteArrayInput
          optionText={(record) => {
            if (record == null) {
              return "";
            }
            if (record && record.description_1) {
              return `${record.region_code} - ${record.description_1}`;
            }

            return "";
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
