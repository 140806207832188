import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const VisionsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth />
      <TextInput source="name" fullWidth={true} />
      <MediaUploader attribute="logo" label="Image" accept="images/*" />
    </SimpleForm>
  </Create>
);

