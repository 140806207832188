import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput,
  required,
} from 'react-admin';
import { getProductOptionText } from '../../../utils/functions';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const SeriesItemCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source='header' fullWidth={true} />
      <TextInput source='product_txt' fullWidth={true} />
      <ReferenceArrayInput
        label='Products'
        reference='products'
        source='products'
        filter={{ fetchMode: 'reflist' }}
        fullWidth={true}
      >
        <AutocompleteArrayInput optionText={getProductOptionText} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
