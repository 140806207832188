import React, { useEffect } from 'react';
import { ColorField } from 'react-admin-color-input';
import { List, Datagrid, TextField, DateField, ChipField, ArrayField, SingleFieldList } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';


export const MainGroceryChainList = props => {
    const dispatch = useDispatch();
    const content = `<h1>This is MainGroceryChain Documents list page</h1><br>
    Here you can upload MainGroceryChain PDFs and images by year`
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="name" />
            <TextField source="description" />
            <ArrayField source="countries" fieldKey="id">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                </SingleFieldList>
            </ArrayField>
            <ArrayField source="sub_grocery_chains" fieldKey="id" label="Sub G Chains">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                </SingleFieldList>
            </ArrayField>
            <TextField source="size_of_lin" label="LineSize" />
            <ColorField source="color" />
            <ChipField source="percent" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
)
};