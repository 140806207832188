import React, {useEffect, useState} from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox as MaterialUiCheckbox } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useMutation, useQuery, Loading, Error, usePermissions} from 'react-admin';
import { Button } from "ra-ui-materialui";

export const Permissions = (props) => {
  const { permissions } = usePermissions();
  const [mutate] = useMutation();
  const [attributePermissions, setAttributePermissions] = useState(null);

  const {data, loading, error} = useQuery({
    type: 'getOne',
    resource: 'permissions',
    payload: { id: 'user-permissions' }
  });

  useEffect(() => {
    setAttributePermissions(data);
  }, [data]);

  const handleSubmit = async (event) => {
    return mutate({
      type: 'update',
      resource: 'permissions',
      payload: { data: attributePermissions },
    });
  };

  const toLabel = (string) => string.replace('-', ' ').toUpperCase();

  const useStyles = makeStyles({
    checkboxGroup: {
      marginBottom: '2em',
    },
  });
  const classes = useStyles();

  return <>
    <h1>User permissions</h1>

    {loading && <Loading />}
    {(error || (permissions && permissions.role !== 'Admin')) && <Error />}

    {attributePermissions && <div>

      {Object.keys(attributePermissions).map(endpointName => <div key={`${endpointName}`} className={classes.checkboxGroup}>
        <div>{toLabel(endpointName)}:</div>

        {Object.keys(attributePermissions[endpointName]).map((permission) => (
            <FormControlLabel
                control={
                  <MaterialUiCheckbox
                      checked={attributePermissions[endpointName][permission]}
                      onChange={(event, newValue) => {
                        const newPermissions = { ...attributePermissions };
                        newPermissions[endpointName][permission] = newValue;
                        setAttributePermissions(newPermissions);
                      }}
                      name={`${endpointName}__${permission}`}
                      color='primary'
                  />
                }
                label={permission}
                key={`${endpointName}__${permission}`}
            />
        ))}
      </div>)}
      <Button label="Save" variant="contained" color="primary" onClick={handleSubmit} />
    </div>}


  </>;
};


