import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';

export const SalesPricesList = (props) => {
    const dispatch = useDispatch();
    const content = "<h1>This is SalesPrice list</h1>"
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' }})
})

    return (
        <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
            <Datagrid rowClick="edit">
                <TextField source="region_code" />
                <TextField source="type" />
                <TextField source="code" />
                <FunctionField label="Retailer" render={record => {
                    if (record && record.retailer) {
                        return '✔';
                    }
                    return '✖';
                }} />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </Datagrid>
        </List>
    )
};