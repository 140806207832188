import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'logistic-media';

export const LogisticMediaEdit = (props) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
          <SelectInput
            source='region_code'
            choices={makeRegionChoices()}
            validate={[required()]}
            fullWidth
          />
        ) : null}
        {isFieldAvailable('media', props, RESOURCE_NAME) ? (
          <MediaUploader attribute='media' label='Media' multiple accept='images/*' />
        ) : null}
        {isFieldAvailable('name', props, RESOURCE_NAME) && (
          <TextInput label='Name' source='name' fullWidth={true} />
        )}
      </SimpleForm>
    </Edit>
  );
};
