import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'concept';

export const ConceptEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('logo', props, RESOURCE_NAME) && (
        <MediaUploader attribute='logo' label='Logo' accept='images/*' />
      )}
      {isFieldAvailable('media', props, RESOURCE_NAME) && (
        <MediaUploader attribute='media' label='Images' multiple accept='images/*' />
      )}
      {isFieldAvailable('name', props, RESOURCE_NAME) && (
        <TextInput source='name' fullWidth={true} />
      )}
      {isFieldAvailable('description', props, RESOURCE_NAME) && (
        <TextInput label='Description' source='description' fullWidth={true} />
      )}
    </SimpleForm>
  </Edit>
);
