import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';

export const ContinentList = props => {
  const dispatch = useDispatch();
  const content = `<h1>This is Continents list page</h1><br>Here you can add description on the continents`
  useEffect(() => {
  dispatch({ type: 'HELP_MESSAGE', payload: { content }})
  dispatch({ type: 'HELP_IMAGE', payload: { content: '' }})
})
  return (
  <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="region_code" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)
};