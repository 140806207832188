import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, ChipField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';

export const CsrList = props => {
    const dispatch = useDispatch();
    const content = `<h1>This is Csr Documents list page</h1><br>
    Here you can upload Csr PDFs and images by year`
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="title" />
            <ChipField source="year" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
)
};