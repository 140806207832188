import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin'
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'language';

export const LanguageEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ?
          <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth /> : null }
          {isFieldAvailable('name', props, RESOURCE_NAME) ? <TextInput source="name" fullWidth={true} /> : null}
      </SimpleForm>
  </Edit>
);