import React from 'react';
import {
  SelectInput,
  required,
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { getProductOptionText } from '../../../utils/functions';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const BrandCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput fullWidth={true} source='name' />
      <ReferenceArrayInput
        fullWidth={true}
        label='Products'
        reference='products'
        source='products'
        filter={{ fetchMode: 'reflist' }}
      >
        <AutocompleteArrayInput optionText={getProductOptionText} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
