import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin'
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'visions';

export const VisionsEdit = props => (
  <Edit {...props}>
    <SimpleForm>
    {isFieldAvailable('region_code', props, RESOURCE_NAME) ?
          <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth /> : null }
      <MediaUploader attribute="logo" label="Image" accept="images/*" />
      {isFieldAvailable('name', props, RESOURCE_NAME) && <TextInput source="name" fullWidth={true} />}
    </SimpleForm>
  </Edit>
);