import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin'
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { logisticHelp } from '../../help';

export const LogisticMediaList = props => {
    const dispatch = useDispatch();
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: logisticHelp }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="name" />
            <FunctionField label="Media" render={record => {
                if (record.media && record.media.length) {
                    return '✔';
                }
                return '✖';
            }} />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
)
};