import { REGION_CODES } from './constants';

export const makeRegionChoices = () => {
  const userRole = localStorage.getItem('roleName')
  const regionCode = localStorage.getItem('regionCode')

  if (userRole === 'Admin') {
    return REGION_CODES
  } else {
    return [{ id: regionCode, name: regionCode }]
  }
}
