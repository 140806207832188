import React from 'react';
import PropTypes from 'prop-types';
import {DateInput} from 'react-admin';
import {useField} from 'react-final-form';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const DateInputWithSetter = props => {
    const { daysToChangeWithButton, customLabel } = props;
    const {
        input: { onChange },
    } = useField(props.attribute);

    const classes = makeStyles({
        setButton: {
            position: 'relative',
            top: '13px',
            left: '10px',
        }
    })();

    const prefix = daysToChangeWithButton < 0 ? '' : '+';
    const moreThanOne = Math.abs(daysToChangeWithButton) > 1;
    const label = customLabel || `Set ${prefix}${daysToChangeWithButton} day${moreThanOne ? 's' : ''} from now`;

    const setDate = event => {
        event.preventDefault();
        const currentDate = new Date();
        onChange(new Date(currentDate.setDate(currentDate.getDate() + daysToChangeWithButton)));
    };

    return <>
        <DateInput source="new_until" />
        {daysToChangeWithButton &&
            <Button variant="contained" onClick={setDate} className={classes.setButton}>{label}</Button>
        }
    </>;
};

DateInputWithSetter.propTypes = {
    attribute: PropTypes.string.isRequired,
    customLabel: PropTypes.string,
    daysToChangeWithButton: PropTypes.number,
};

DateInputWithSetter.defaultProps = {
    customLabel: '',
    daysToChangeWithButton: 30,
};

export default DateInputWithSetter;