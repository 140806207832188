import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export const TranslationCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="translate_key" fullWidth={true} />
          <TextInput source="translate_dk" fullWidth={true} />
          <TextInput source="translate_no" fullWidth={true} />
          <TextInput source="translate_se" fullWidth={true} />
      </SimpleForm>
  </Create>
);