import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import { makeRegionChoices } from "../../../utils/regionSelector";

const RESOURCE_NAME = "brand";

export const SalesPricesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable("type", props, RESOURCE_NAME) ? (
        <TextInput fullWidth={true} source="type" />
      ) : null}
      {isFieldAvailable("code", props, RESOURCE_NAME) ? (
        <TextInput fullWidth={true} source="code" />
      ) : null}
      {isFieldAvailable("product", props, RESOURCE_NAME) ? (
        <ReferenceInput
          fullWidth={true}
          label="Product"
          reference="products"
          source="product"
          filter={{ fetchMode: "reflist" }}
        >
          <AutocompleteInput
            optionText={(product = {}) => {
              if (product == null) {
                return "";
              }
              const { description_1 = "" } = product;
              return `${description_1}`;
            }}
          />
        </ReferenceInput>
      ) : null}
      {isFieldAvailable("retailer", props, RESOURCE_NAME) && (
        <ReferenceInput
          fullWidth={true}
          label="Retailer"
          reference="retailers"
          source="retailer"
          filter={{ fetchMode: "reflist" }}
        >
          <AutocompleteInput
            optionText={(retailer = {}) => {
              if (retailer == null) {
                return "";
              }
              const { region_code = "", name = "" } = retailer;
              // was 'name'
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Edit>
);
