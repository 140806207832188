import React, {useState} from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useStore } from 'react-redux';

const getModalStyle = () => {
    return {
        position: 'absolute',
        top: '100px',
        left: '50%',
        marginLeft: '-500px',
        display: 'block!important',
        width: '1000px',
        height: '500px',
        overflow: 'scroll',
        minHeight: '100%',
        color: '#282828',
        backgroundColor: 'white',
        padding: '30px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    }
};

const helpImageStyle = {
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center'
}

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    helpImage: {
      ...helpImageStyle,
      height: '100%',
    },
    rangeHelpImage: {
      ...helpImageStyle,
      height: '200%',
    }
});

const GeiaAppBar = props => {

    const [isOpen, setIsOpen] = useState(false);

    const handleHelpClick = () => {
        setIsOpen(isOpen=>!isOpen);
    }

    const classes = useStyles();
    const store = useStore();
    const reduxState = store.getState();
    const isRangePage = reduxState.router.location.pathname === '/ranges'
    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
          <span className={classes.spacer} />
            {isOpen && (
                <div style={{ ...getModalStyle(), transition: `${isOpen ? "opacity .8s ease-in-out" : ""}` }}>
                    <Button variant="contained" color="primary" onClick={() => handleHelpClick()}>{isOpen? "Close": "Help"}</Button>
                    <div dangerouslySetInnerHTML={{ __html: reduxState.helpText }} />
                    <div className={isRangePage ? classes.rangeHelpImage : classes.helpImage} style={{ backgroundImage: reduxState.helpImage }}></div>
                </div>

            )}
            <Button variant="contained" color="primary" onClick={() => handleHelpClick()}>{isOpen? "Close": "Help"}</Button>
        </AppBar>
    );
};

export default GeiaAppBar;