import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, ChipField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { certificateHelp } from '../../help';

export const CertificationList = props => {
    const dispatch = useDispatch();
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: certificateHelp }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="title" />
            <TextField source="description" />
            <ChipField source="year" />
            <ChipField source="type" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
)
};