import React, { useEffect, useState } from 'react';
import { europe, world, asia } from './';

const pinStyle = {
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: 'black',
  position: 'absolute'
}

const continentSelector = {
  europe,
  asia,
  world,
}

const viewBoxSelector = {
  europe: '0 0 335 679',
  asia: '0 0 360 220',
  world: '0 0 500 350',
}

const isCountry = (country, countryCode) => country.find(({ id = '' }) => id.toLowerCase() === countryCode.toLowerCase())

const getContinent = (countryCode = '') => {
  const isEurope = isCountry(europe, countryCode)
  const isAsia = isCountry(asia, countryCode)
  const isWorld = isCountry(world, countryCode)
  return isEurope ? 'europe' : isAsia ? 'asia' : isWorld ? 'world' : ''
}

export default ({ countryCode = '', posX = 0, posY = 0, handleMapClick = () => { } }) => {
  const continentName = getContinent(countryCode)
  const actualViewBox = viewBoxSelector[continentName]
  const defaultSize = { width: 'auto', height: 'auto' }
  const [box, setBox] = useState(actualViewBox)
  const [style, setStyle] = useState(defaultSize)
  const [bound, setBound] = useState({})
  const actualContinent = continentSelector[continentName] || []
  const { d = '', id = '', name = '', color = '#6fa51' } = actualContinent.find(({ id }) => id.toLowerCase() === countryCode.toLowerCase()) || {}
  const TEN_PERCENT = 0.1
  useEffect(() => {
    if (box !== actualViewBox && !Object.keys(bound).length) {
      const pathElement = document.querySelector(`#${id}-path`)
      if (pathElement) {
        const pathBound = pathElement.getBoundingClientRect() || {}
        setBound(pathBound)
      }
    }
    if (box === actualViewBox && actualViewBox && continentName) {
      const svgElement = document.querySelector(`#${id}-svg`)
      const pathElement = document.querySelector(`#${id}-path`)
      if (svgElement && pathElement) {
        const viewBoxArray = actualViewBox.split(' ')
        const viewBoxArrayNumbers = viewBoxArray.map(number => parseInt(number))
        const [vbX = 0, vbY = 0, vbWidth = 0, vbHeight = 0] = viewBoxArrayNumbers
        const {
          x: svgX = 0,
          y: svgY = 0,
          width: svgWidth = 0,
          height: svgHeight = 0,
        } = svgElement.getBoundingClientRect() || {}
        const pathBound = pathElement.getBoundingClientRect() || {}
        const {
          x: pathX = 0,
          y: pathY = 0,
          width: pathWidth = 0,
          height: pathHeight = 0,
        } = pathBound
        const widthRatio = vbWidth / svgWidth
        const heightRatio = vbHeight / svgHeight
        const newVbX = ((pathX - svgX) * widthRatio) + vbX
        const newVbY = ((pathY - svgY) * heightRatio) + vbY
        let newVbWidth = pathWidth * widthRatio
        const newVbHeight = pathHeight * heightRatio
        if (newVbHeight > newVbWidth) {
          newVbWidth = newVbHeight + (newVbHeight * TEN_PERCENT)
        }
        else {
          setStyle({
            width: '100%',
            height: '100%'
          })
        }
        const newBox = `${newVbX} ${newVbY} ${newVbWidth} ${newVbHeight}`
        setBox(newBox)
      }
    }
  }, [box]); // actualViewBox, bound, continentName, id - cannot use, gets circular updates
  
  const { width = 0, height = 0 } = bound || {}
  const pinLeft = (width * (posX / 100)) - 10 || 0
  const pinTop = (height * (posY / 100)) - 10 || 0
  return (
    <div style={{ maxWidth: '500px', maxHeight: '500px', margin: 'auto' }}>
      <div className='supplier-pin' style={{ ...pinStyle, marginLeft: pinLeft, marginTop: pinTop }} />
      <svg id={`${id}-svg`} style={style} viewBox={box} preserveAspectRatio="xMaxYMax meet" xmlns="http://www.w3.org/2000/svg">
        <path className='country-selector' id={`${id}-path`} name={name} fill={color} d={d} onClick={event => handleMapClick(event, `${id}-path`)} />
      </svg>
    </div>
  )
}
