import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  ReferenceInput,
  required,
} from "react-admin";
import MediaUploader from "../../components/MediaUploader";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const CountryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader
        attribute="background"
        label="Background"
        accept="images/*"
      />
      <TextInput source="name" fullWidth={true} />
      <TextInput source="description" fullWidth={true} />
      <TextInput source="code" fullWidth={true} />

      <ReferenceInput
        source="continent"
        reference="continents"
        filter={{ fetchMode: "reflist" }}
        label="Continent"
        fullWidth={true}
      >
        <SelectInput
          optionText={(supplier = {}) => {
            if (supplier == null) {
              return "";
            }
            const { region_code = "", name = "" } = supplier;
            return `${region_code} - ${name}`;
          }}
        />
      </ReferenceInput>

      <ReferenceArrayInput
        label="Suppliers"
        reference="suppliers"
        source="suppliers"
        filter={{ fetchMode: "reflist" }}
        fullWidth={true}
      >
        <AutocompleteArrayInput
          optionText={(supplier = {}) => {
            if (supplier == null) {
              return "";
            }
            const { region_code = "", name = "" } = supplier;
            return `${region_code} - ${name}`;
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
