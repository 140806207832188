import React, { useEffect } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';

export const WarehouseAttributesList = props => {
    const dispatch = useDispatch();
    const content = `<h1>Warehouse attributions list</h1><br>You can set attributions to the different warehouses here`
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: content }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="name" />
        </Datagrid>
    </List>
)
};