import React from 'react'
import {Create, SimpleForm, TextInput, required, SelectInput} from 'react-admin'
import {ColorInput} from 'react-admin-color-input';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const TemperatureCreate = props => (
  <Create {...props}>
    <SimpleForm>
            <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth />
          <TextInput source="name" fullWidth={true} />
          <ColorInput source="color" fullWidth={true} />
      </SimpleForm>
  </Create>
);