import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { seriesHelp } from '../../help'; // TODO fsMatchHelp

export const FsMatchList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: seriesHelp } });
  });
  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='header' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};
