import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
} from "react-admin";
import MediaUploader from "../../components/MediaUploader";
import { ColorInput } from "react-admin-color-input";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const SubGroceryChainCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <MediaUploader attribute="logo" label="Logo" accept="images/*" />
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source="name" fullWidth={true} />
      <TextInput source="number" fullWidth={true} />
      <TextInput source="description" fullWidth={true} />
      <ColorInput source="logo_bg_color" fullWidth={true} />
      <ReferenceInput
        source="main_grocery_chain"
        reference="main-grocery-chains"
        filter={{ fetchMode: "reflist" }}
        allowEmpty
        label="Main Grocery Chain"
        fullWidth={true}
      >
        <AutocompleteInput
          optionText={(chain = {}) => {
            if (chain == null) {
              return "";
            }
            const { region_code = "", name = "" } = chain;
            return `${region_code} - ${name}`;
          }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
