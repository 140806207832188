import React, { Fragment } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';

export const MedieaLibraryList = props => {
  return (
    <Fragment>
      <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
          <TextField source="name" />
        </Datagrid>
      </List>
    </Fragment>
  )
};
