import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import useApiDetails from './ApiDetailsContext';

const prepareIdsChunks = (arr, splitSize = 20) => {
  const splittedArray = [];

  for (let idx = 0; idx < arr.length; idx = idx + splitSize) {
    let chunk = arr.slice(idx, idx + splitSize);

    // After visiting a list page, ids may be present as objects already fetched instead of string
    chunk = chunk.map((element) => {
      if (element && typeof element === 'object') {
        return element.id || element._id;
      }

      return element;
    });

    splittedArray.push(chunk);
  }

  return splittedArray;
};

const httpClientDefault = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  //if(url.indexOf('uploadfiles'))
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
/**
 * Maps react-admin queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (uploadFields = [], httpClient = httpClientDefault) => {
  const { dataUrl: apiUrl } = useApiDetails();

  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};

    if (resource === 'permissions') {
      const resourcePath = 'geia-acl/user-role/write-permissions';
      if (type === GET_ONE) {
        url = `${apiUrl}/${resourcePath}`;
      }

      if (type === UPDATE) {
        url = `${apiUrl}/${resourcePath}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
      }
    } else if (resource === 'user-roles') {
      const resourcePath = 'users-permissions/roles';
      if (type === GET_LIST) {
        url = `${apiUrl}/${resourcePath}?${adjustQueryForStrapi(params)}`;
      }
    } else {
      if (resource === 'medialibrary') {
        resource = 'upload/files';
      }

      switch (type) {
        case GET_LIST:
          url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
          break;
        case GET_MANY_REFERENCE:
          url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
          break;
        case GET_ONE:
          url = `${apiUrl}/${resource}/${params.id}`;
          break;
        case UPDATE:
          url = `${apiUrl}/${resource}/${params.id}`;
          options.method = 'PUT';
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
          options.body = JSON.stringify(data);
          break;
        case CREATE:
          url = `${apiUrl}/${resource}`;
          options.method = 'POST';
          options.body = JSON.stringify(params.data);
          break;
        case DELETE:
          url = `${apiUrl}/${resource}/${params.id}`;
          options.method = 'DELETE';
          break;
        default:
          throw new Error(`Unsupported fetch action type ${type}`);
      }
    }

    return { url, options };
  };

  const adjustQueryForStrapi = (params) => {
    /*
      params = {
          pagination: { page: {int} , perPage: {int} },
          sort: { field: {string}, order: {string} },
          filter: {Object},
          target: {string}, (REFERENCE ONLY)
          id: {mixed} (REFERENCE ONLY)
      }
      */

    // Handle SORTING
    const s = params.sort;
    let sort = s.field === '' ? '_sort=updated_at:DESC' : '_sort=' + s.field + ':' + s.order;

    // Handle FILTER
    let filter = '';
    if (params.hasOwnProperty('filter')) {
      const f = params.filter;
      const keys = Object.keys(f);
      for (let i = 0; i < keys.length; i++) {
        //react-admin uses q filter in several components and strapi use _q
        if (keys[i] === 'q' && f[keys[i]] !== '') {
          filter += '_q=' + f[keys[i]] + (keys[i + 1] ? '&' : '');
        } else {
          filter += keys[i] + '=' + f[keys[i]] + (keys[i + 1] ? '&' : '');
        }
      }
    }

    if (params.hasOwnProperty('id') && params.hasOwnProperty('target')) {
      if (params.id && params.target && params.target.indexOf('_id') !== -1) {
        const target = params.target.substring(0, params.target.length - 3);
        filter += '&' + target + '=' + params.id;
      }
    }

    // Handle PAGINATION
    const { page, perPage } = params.pagination;
    const start = (page - 1) * perPage;
    const limit = perPage; //for strapi the _limit params indicate the amount of elements to return in the response
    const range = '_start=' + start + '&_limit=' + limit;

    if (filter !== '') {
      return sort + '&' + range + '&' + filter;
    }

    return sort + '&' + range;
  };

  // Determines if there are new files to upload
  const determineUploadFieldNames = (params) => {
    if (!params.data) return [];
    // Check if the field names are mentioned in the uploadFields
    // and verify there are new files being added
    const requestUploadFieldNames = [];
    Object.keys(params.data).forEach((field) => {
      let fieldData = params.data[field];
      if (uploadFields.includes(field)) {
        fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
        fieldData.filter((f) => f && f.rawFile instanceof File).length > 0 &&
          requestUploadFieldNames.push(field);
      }
    });

    // Return an array of field names where new files are added
    return requestUploadFieldNames;
  };

  // Handles file uploading for CREATE and UPDATE types
  const handleFileUpload = (type, resource, params, uploadFieldNames) => {
    const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;

    const id = type === UPDATE ? `/${params.id}` : '';
    const url = `${apiUrl}/${resource}${id}`;
    const requestMethod = type === UPDATE ? 'PUT' : 'POST';
    const formData = new FormData();

    for (let fieldName of uploadFieldNames) {
      let fieldData = params.data[fieldName];
      fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
      const existingFileIds = [];
      for (let item of fieldData) {
        item.rawFile instanceof File
          ? formData.append(`files.${fieldName}`, item.rawFile, item.rawFile.name)
          : existingFileIds.push(item.id || item._id);
      }

      delete data[fieldName];
    }
    formData.append('data', JSON.stringify(data));

    return httpClient(url, {
      method: requestMethod,
      body: formData,
    }).then((response) => ({ data: replaceRefObjectsWithIds(response.json) }));
  };

  // Replace reference objects with reference object IDs
  const replaceRefObjectsWithIds = (json) => {
    Object.keys(json).forEach((key) => {
      const fd = json[key]; // field data
      const referenceKeys = [];
      if (fd && (fd.id || fd._id) && !fd.mime) {
        json[key] = fd.id || fd._id;
      } else if (Array.isArray(fd) && fd.length > 0 && !fd[0].mime) {
        fd.map((item) => referenceKeys.push(item.id || item._id));
        json[key] = referenceKeys;
      }
    });
    return json;
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
      case GET_ONE:
        return { data: replaceRefObjectsWithIds(json) };
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (resource === 'user-roles') {
          return { data: json };
        }
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data: json,
          total: parseInt(headers.get('content-range').split('/').pop(), 10),
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case DELETE:
        return { data: { id: null } };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // Handle file uploading
    const uploadFieldNames = determineUploadFieldNames(params);

    if (uploadFieldNames.length > 0) {
      return handleFileUpload(type, resource, params, uploadFieldNames);
    }

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) => {
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
          return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
          });
        })
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // Supporting 'fetchMode' is necessary at the backend for each entity's 'find' route
    if (type === GET_MANY) {
      const idsSplitted = prepareIdsChunks(params.ids, 25);

      return Promise.all(
        idsSplitted.map((chunk) => {
          if (chunk.length) {
            return httpClient(
              `${apiUrl}/${resource}?id_in=${chunk.join('&id_in=')}&fetchMode=reflist`,
              {
                method: 'GET',
              }
            );
          } else {
            return [];
          }
        })
      ).then((responses) => {
        const result = responses
          .map((response) => {
            return response.json;
          })
          .flat();

        return {
          data: result,
        };
      });
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params)
    );
  };
};

/*
  Ineffective previous solution used getOne:

  //strapi doesn't handle filters in GET route
  if (type === GET_MANY) {
      return Promise.all(
        params.ids.map((i) =>
          httpClient(`${apiUrl}/${resource}/${i.id || i._id || i}`, {
            method: 'GET',
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

*/
