import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, SelectInput, required } from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'warehouse-attribute';

export const WarehouseAttributesEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('name', props, RESOURCE_NAME) && (
        <TextInput fullWidth={true} source='name' />
      )}
      {isFieldAvailable('frost', props, RESOURCE_NAME) && <BooleanInput source='frost' />}
      {isFieldAvailable('cool', props, RESOURCE_NAME) && <BooleanInput source='cool' />}
      {isFieldAvailable('dry', props, RESOURCE_NAME) && <BooleanInput source='dry' />}
      {isFieldAvailable('road', props, RESOURCE_NAME) && <BooleanInput source='road' />}
      {isFieldAvailable('rail', props, RESOURCE_NAME) && <BooleanInput source='rail' />}
      {isFieldAvailable('sea', props, RESOURCE_NAME) && <BooleanInput source='sea' />}
      {isFieldAvailable('air', props, RESOURCE_NAME) && <BooleanInput source='air' />}
    </SimpleForm>
  </Edit>
);
