import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, SelectInput, required } from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'value';

export const ValuesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <MediaUploader attribute='logo' label='Image' accept='images/*' />
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('description', props, RESOURCE_NAME) && (
        <TextInput source='description' fullWidth={true} />
      )}
      {isFieldAvailable('order', props, RESOURCE_NAME) ? (
        <NumberInput min='1' fullWidth={true} source='order' />
      ) : null}
    </SimpleForm>
  </Edit>
);
