import React, { Fragment, useEffect } from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  DateField,
  NumberField,
  BooleanField,
} from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { supplierHelp } from '../../help';

export const SupplierList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: supplierHelp } });
  });
  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='name' />
        <NumberField source='number_new' label='Number' />
        <FunctionField
          label='Media'
          sortBy='media'
          render={(record) => {
            if (record.media && record.media.length) {
              return (
                <Fragment>
                  <CheckIcon title='Yes' />
                  {` ${record.media.length}`}
                </Fragment>
              ); // '✔';
            } else {
              return <CloseIcon />; //'✖';
            }
          }}
        />
        <BooleanField source='bobbler' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};
