import React, { useEffect } from 'react';
import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { cloneElement } from 'react';
import { TopToolbar, CreateButton, ExportButton, sanitizeListRestProps } from 'react-admin';

const MAX_NUMBER_OF_USERS = 200;

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton
      basePath={basePath}
      disabled={total === MAX_NUMBER_OF_USERS || total > MAX_NUMBER_OF_USERS}
    />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export const UserList = (props) => {
  const dispatch = useDispatch();
  const content = `<h1>User list</h1><br>You can add new users here or edit existing ones`;
  useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' } });
  });
  return (
    <List
      bulkActionButtons={false}
      {...props}
      pagination={<PostPagination />}
      filters={<PostFilter />}
      actions={<ListActions />}
    >
      <Datagrid rowClick='edit'>
        <TextField source='region_code' />
        <TextField source='role.name' label='Role' />
        <TextField source='username' />
        <EmailField source='email' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </Datagrid>
    </List>
  );
};
