import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const MainGroceryChainCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source="name" fullWidth={true} />
      <TextInput source="description" fullWidth={true} />
      <TextInput source="percent" fullWidth={true} />
      <ColorInput source="color" fullWidth={true} />
      <RadioButtonGroupInput
        source="side_left"
        label="Position"
        choices={[
          { id: true, name: "Left" },
          { id: false, name: "Right" },
        ]}
      />
      <TextInput source="size_of_lin" labe="LineSize" fullWidth={true} />
      <ReferenceArrayInput
        fullWidth={true}
        label="Sub grocery chains"
        reference="sub-grocery-chains"
        source="sub_grocery_chains"
        filter={{ fetchMode: "reflist" }}
      >
        <AutocompleteArrayInput
          optionText={(chain = {}) => {
            if (chain == null) {
              return "";
            }
            const { region_code = "", name = "" } = chain;
            return `${region_code} - ${name}`;
          }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        fullWidth={true}
        label="Countries"
        reference="countries"
        source="countries"
        filter={{ fetchMode: "reflist" }}
      >
        <AutocompleteArrayInput
          optionText={(countries = {}) => {
            if (countries == null) {
              return "";
            }
            const { region_code = "", name = "" } = countries;
            return `${region_code} - ${name}`;
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
