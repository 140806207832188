import React from 'react';
import { Create, SimpleForm, NumberInput, TextInput, SelectInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const ValuesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source='description' fullWidth={true} />
      <MediaUploader attribute='logo' label='Image' accept='images/*' />
      <NumberInput min='1' source='order' fullWidth={true} />
    </SimpleForm>
  </Create>
);
