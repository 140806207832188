import React from 'react';
import { makeRegionChoices } from '../../../utils/regionSelector';
import {Create, SimpleForm, TextInput, BooleanInput, SelectInput, required } from 'react-admin'

export const WarehouseAttributesCreate = props => (
  <Create {...props}>
    <SimpleForm>
          <SelectInput source="region_code" choices={makeRegionChoices()} validate={[required()]} fullWidth />
          <TextInput fullWidth={true} source="name" />
          <BooleanInput source="frost" />
          <BooleanInput source="cool" />
          <BooleanInput source="dry" />
          <BooleanInput source="road" />
          <BooleanInput source="rail" />
          <BooleanInput source="sea" />
          <BooleanInput source="air" />
      </SimpleForm>
  </Create>
);

