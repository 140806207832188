import React, { useEffect } from 'react';
import { ColorField } from 'react-admin-color-input';
import { List, Datagrid, TextField, DateField, ChipField, FunctionField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';



export const SubGroceryChainList = props => {
  const dispatch = useDispatch();
  const content = `<h1>This is SubGroceryChain Documents list page</h1><br>
    Here you can set the sub grocery chains`
    useEffect(() => {
  dispatch({ type: 'HELP_MESSAGE', payload: { content } })
  dispatch({ type: 'HELP_IMAGE', payload: { content: '' } })
})
  return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="region_code" />
        <TextField source="name" />
        <TextField source="number" />
        <TextField source="description" />
        <ChipField source="main_grocery_chain.name" label="M Grocery Chain" />
        <ColorField source="logo_bg_color" />
        <FunctionField label="Logo" render={record => {
          if (record && record.logo) {
            return '✔';
          }
          return '✖';
        }} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  )
};