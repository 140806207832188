import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { countryHelp } from '../../help';

export const CountryList = props => {
  const dispatch = useDispatch();
  useEffect(() => {
  dispatch({ type: 'HELP_MESSAGE', payload: { content: '' }})
  dispatch({ type: 'HELP_IMAGE', payload: { content: countryHelp }})
})
  return (
  <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="region_code" />
      <TextField source="name" />
      <TextField label="Country Code" source="code" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <FunctionField label="Own Description" sortBy="description" render={record => {
        if (record && record.description) {
          return '✔';
        }
        return '✖';
      }} />
      <FunctionField label="Own Background" sortBy="background" render={record => {
        if (record && record.background) {
          return '✔';
        }
        return '✖';
      }} />
    </Datagrid>
  </List >
)
};