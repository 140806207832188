import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  maxValue,
  required,
} from 'react-admin';
import MapExporter from '../../maps/MapExporter';
import { countryList } from '../../maps/countries';
import { getProductOptionText } from '../../../utils/functions';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const SupplierCreate = (props) => {
  const [pin, setPin] = useState([-1, -1]);

  const FormSetter = () => {
    const form = useForm();
    if (pin[0] !== -1 || pin[1] !== -1) {
      form.change('map_pos_x', pin[0]);
      form.change('map_pos_y', pin[1]);
    }
    return null;
  };

  const handleMapClick = (event = {}, pathId = '') => {
    const pathElement = document.querySelector(`#${pathId}`);
    if (pathElement) {
      const { clientY = 0, clientX = 0 } = event;
      const {
        bottom = 0,
        right = 0,
        width = 0,
        height = 0,
      } = pathElement.getBoundingClientRect() || {};
      const relativeWidthDiff = right - clientX;
      const relativeHeightDiff = bottom - clientY;
      const widthPercentage = 100 - (relativeWidthDiff / width) * 100;
      const heightPercentage = 100 - (relativeHeightDiff / height) * 100;
      setPin([Math.round(widthPercentage), Math.round(heightPercentage)]);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
        <TextInput source='name' fullWidth={true} />
        <TextInput source='address_1' fullWidth={true} />
        <TextInput source='address_2' fullWidth={true} />
        <BooleanInput source='bobbler' fullWidth={true} label='Bobbler / Prevent overwrite' />
        <TextInput source='number' fullWidth={true} label='Number (old)' />
        <TextInput source='number_new' fullWidth={true} label='Number (new)' />
        <TextInput source='zip_code' fullWidth={true} />
        <TextInput source='city' fullWidth={true} />
        <TextInput source='att' fullWidth={true} />
        <TextInput source='phone' fullWidth={true} />
        <TextInput source='short_description' fullWidth={true} />
        <TextInput source='email' fullWidth={true} />
        <TextInput source='description' fullWidth={true} />

        <ReferenceArrayInput
          label='Categories'
          reference='categories'
          source='categories'
          filter={{ fetchMode: 'reflist' }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(categories = {}) => {
              if (categories == null) {
                return '';
              }
              const { region_code = '', sys_name = '' } = categories;
              return region_code && sys_name ? `${region_code} - ${sys_name}` : '';
            }}
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          label='Products'
          reference='products'
          source='products'
          filter={{ fetchMode: 'reflist' }}
          fullWidth={true}
        >
          <AutocompleteArrayInput optionText={getProductOptionText} />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          label='Ranges'
          reference='ranges'
          source='ranges'
          filter={{ fetchMode: 'reflist' }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(ranges = {}) => {
              if (ranges == null) {
                return '';
              }
              const { region_code = '', name = '' } = ranges;
              return region_code && name ? `${region_code} - ${name}` : '';
            }}
          />
        </ReferenceArrayInput>
        <AutocompleteInput source='country_code' choices={countryList} fullWidth />
        <>
          <FormSetter />
          <FormDataConsumer>
            {({ formData }) => {
              const { country_code = '', map_pos_x = 0, map_pos_y = 0 } = formData || {};
              return (
                <MapExporter
                  countryCode={country_code}
                  posX={map_pos_x}
                  posY={map_pos_y}
                  handleMapClick={handleMapClick}
                />
              );
            }}
          </FormDataConsumer>
        </>
        <TextInput
          source='map_pos_x'
          validate={maxValue(100, 'Max value is 100')}
          fullWidth={true}
          label='Position in % from LEFT on country map'
        />
        <TextInput
          source='map_pos_y'
          validate={maxValue(100, 'Max value is 100')}
          fullWidth={true}
          label='Position in % from TOP on country'
        />
        <ReferenceInput
          source='continent'
          reference='continents'
          filter={{ fetchMode: 'reflist' }}
          label='Continent'
          fullWidth={true}
        >
          <AutocompleteInput />
        </ReferenceInput>

        <TextInput source='site_url' fullWidth={true} />
        <TextInput source='size_of_lin' label='Length of pin in px' fullWidth={true} />
      </SimpleForm>
    </Create>
  );
};
