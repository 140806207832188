// in src/Menu.js
import * as React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';

const Menu = ({ onMenuClick, logout }) => {
  const { permissions } = usePermissions();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  return (
    <div>
      <MenuItemLink
        to='/products'
        primaryText='Products'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {/*<MenuItemLink to="/medialibrary" primaryText="Media-library" leftIcon={<LabelIcon />} onClick={onMenuClick} sidebarIsOpen={open} />*/}
      <MenuItemLink
        to='/temperatures'
        primaryText='Temperatures'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/series-items'
        primaryText='Series'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/fs-matches'
        primaryText='FS Matches'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/customers'
        primaryText='Customers'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/brands'
        primaryText='Brands'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/languages'
        primaryText='Languages on pck'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/ranges'
        primaryText='Ranges'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/categories'
        primaryText='Categories'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/category-relations'
        primaryText='Category Relations'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/consumer-labels'
        primaryText='Consumer Labels'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/suppliers'
        primaryText='Suppliers'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/continents'
        primaryText='Continents'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/retailers'
        primaryText='Retailers'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/main-grocery-chains'
        primaryText='Main Grocery Chains'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/sub-grocery-chains'
        primaryText='Sub Grocery Chains'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/visions'
        primaryText='Vision/Mission'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/values'
        primaryText='Values'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/countries'
        primaryText='Countries'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/translations'
        primaryText='Translations'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/logistic-medias'
        primaryText='Logistic Media'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/gatekeepers'
        primaryText='GateKeeper'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/concepts'
        primaryText='Concept'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/certifications'
        primaryText='Certifications'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/csrs'
        primaryText='Csr Documents'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/teams'
        primaryText='Team Members'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/warehouse-attributions'
        primaryText='Warehouse Attributions'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/logs'
        primaryText='Logs'
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      {permissions && permissions.role === 'Admin' && (
        <MenuItemLink
          to='/tools'
          primaryText='Tools'
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {permissions && permissions.role === 'Admin' && (
        <MenuItemLink
          to='/permissions'
          primaryText='Permissions'
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {permissions && permissions.role === 'Admin' && (
        <MenuItemLink
          to='/users'
          primaryText='Users'
          leftIcon={<LabelIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
    </div>
  );
};

export default withRouter(Menu);
