import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';

export const ConceptCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <SelectInput
        source='region_code'
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader label='Logo' attribute='logo' />
      <MediaUploader label='Images' attribute='media' multiple />
      <TextInput label='Header' source='name' fullWidth />
      <TextInput label='Description' source='description' fullWidth={true} />
    </SimpleForm>
  </Create>
);
